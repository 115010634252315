import {FileModel} from "./file.model";

export class CompanyFileModel extends FileModel {
    id: number;
    createdBy: string;
    size: string;
    description: string;

    constructor(obj?: any) {
        super();
        if (obj) {
            this.bind(obj);
        }
    }

    bind(obj: any) {
        super.bind(obj)
        this.id = obj?.id;
        this.createdBy = obj?.createdBy;
        this.size = obj?.size;
        this.description = obj?.description;
        return this;
    }
}
