import { Component, Input } from '@angular/core';
import { STATUS } from '../../../../shared/model/status.enum';
import { CustomerFinancialStatusComponent } from '../customer-financial-status/customer-financial-status.component';

@Component({
  selector: 'app-financial',
  standalone: true,
  imports: [
    CustomerFinancialStatusComponent,
  ],
  templateUrl: './financial.component.html',
})
export class FinancialComponent {

  protected readonly STATUS = STATUS;
  @Input() status!: STATUS.CUSTOMER_PAID;

}
