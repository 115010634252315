import {Component, OnInit} from '@angular/core';
import {MatTab, MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {BlockCompanyFormComponent} from "../block-company-form/block-company-form.component";
import {BehaviorSubject} from "rxjs";
import { TranslocoPipe } from '@ngneat/transloco';
import { BlockComponent } from '../block/block.component';
import {CompanyListComponent} from "../company-list/company-list.component";

@Component({
  selector: 'app-companies',
  standalone: true,
  imports: [
    MatTab,
    MatTabGroup,
    BlockCompanyFormComponent,
    TranslocoPipe,
    BlockComponent,
    CompanyListComponent,
  ],
  templateUrl: './companies.component.html',
  styleUrl: './companies.component.scss'
})
export class CompaniesComponent implements OnInit {
  refreshCompanies$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshBlockedCompanies$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    this.refreshCompanies$.next(true);
  }

  tabChanged(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.refreshCompanies$.next(true);
    } else if (event.index === 1) {
      this.refreshBlockedCompanies$.next(true);
    }
  }
}
