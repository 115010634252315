import {Component, Input} from '@angular/core';
import { MatButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatIcon} from '@angular/material/icon';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
    selector: 'app-column-selector',
    standalone: true,
  imports: [
    MatButton,
    MatMenu,
    CommonModule,
    MatCheckbox,
    MatIcon,
    MatMenuTrigger,
    TranslocoPipe,
    MatIconButton,
    MatMiniFabButton,
  ],
    templateUrl: './column-selector.component.html',
})
export class ColumnSelectorComponent {
    @Input({required: true}) allColumns: string[];
    @Input({required: true}) displayedColumns: string[];

    isColumnDisplayed(column: string): boolean {
        return this.displayedColumns.includes(column);
    }

    toogleColumn(column: string) {
        const index = this.displayedColumns.indexOf(column);
        if (index >= 0) {
            this.displayedColumns.splice(index, 1);
        } else {
            this.displayedColumns.push(column);
        }
    }
}
