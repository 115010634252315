import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { Subject } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TablePagination } from '../../model/paginate.type';

@Component({
  selector: "app-base-table",
  standalone: true,
  imports: [CommonModule],
  template: ``,
})
export abstract class BaseTableComponent implements OnDestroy {
  public _unsubscribeAll: Subject<any> = new Subject<any>();
  @ViewChild(MatPaginator) public _paginator: MatPaginator;
  @ViewChild(MatSort) public _sort: MatSort;
  abstract dataSource;
  abstract loadData(query?: string): void;
  pagination: TablePagination;
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
