import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { DatePipe, NgIf } from '@angular/common';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource
} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {TranslocoPipe} from "@ngneat/transloco";
import {CompanyModel} from "../../../../shared/model/company.model";
import {BaseTableComponent} from "../../../../shared/components/base-table/base-table.component";
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {MatCardContent} from "@angular/material/card";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {CompanyService} from "../../../../shared/services/company.service";
import {BehaviorSubject, debounceTime, distinctUntilChanged, takeUntil} from "rxjs";
import {ColumnSelectorComponent} from "../../../../shared/components/column-selector/column-selector.component";
import {MatIcon} from "@angular/material/icon";
import {Router} from "@angular/router";
import {CompanyEditComponent} from "../company-edit/company-edit.component";
import {ModalService} from "../../../../shared/services/modal.service";
import { CompanyFileModel } from '../../../../shared/model/company-file.model';
import { Confirmable } from '../../../../shared/decorators/confirmable.decorator';
import { FuseConfirmationService } from '../../../../../@fuse/services/confirmation';

@Component({
    selector: 'app-company-list',
    standalone: true,
    imports: [
        DatePipe,
        MatCell,
        MatCellDef,
        MatColumnDef,
        MatHeaderCell,
        MatHeaderRow,
        MatHeaderRowDef,
        MatPaginator,
        MatRow,
        MatRowDef,
        MatSort,
        MatSortHeader,
        MatTable,
        TranslocoPipe,
        MatHeaderCellDef,
        FormsModule,
        MatCardContent,
        MatFormField,
        MatInput,
        ReactiveFormsModule,
        ColumnSelectorComponent,
        MatIcon,
        NgIf,
    ],
    templateUrl: './company-list.component.html',
})
export class CompanyListComponent extends BaseTableComponent implements OnInit, AfterViewInit, OnDestroy {
    public dataSource: MatTableDataSource<CompanyModel> = new MatTableDataSource<CompanyModel>();
    @Input() refreshMainTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public displayedColumns: string[] = [
        'name',
        'owner',
        'phoneNumber',
        'taxNumber',
        'edit'
    ];
    public allColumns: string[] = [
        'name',
        'owner',
        'type',
        'blocked',
        'phoneNumber',
        'taxNumber',
        'ustId',
        'edit'
    ]
    companyNameFilter: UntypedFormControl = new UntypedFormControl();

    constructor(private confirmationService: FuseConfirmationService,
                private companyService: CompanyService, private router: Router, private modalService: ModalService) {
        super();
    }

    ngOnInit() {
        this.refreshMainTab$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            if (value) {
                this.loadData();
                this.refreshMainTab$.next(false);
            }
        });
        this.companyNameFilter.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.pagination.pageIndex = 0;
                this.loadData();
            });
    }

    ngAfterViewInit(): void {
        this.pagination?.pageIndex ? this.pagination.pageIndex = 0 : ' ';
    }

    loadData(query?: string) {
        const filterValue = this.companyNameFilter.value || '';
        const currentPageIndex = this.pagination?.pageIndex || 0;
        this.companyService.getPaginate<CompanyModel>(`filter?`, {
            page: currentPageIndex,
            size: this._paginator?.pageSize || 20,
            filter: filterValue
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.dataSource.data = response?.data?.content.map((val) => new CompanyModel(val))
                    this.pagination = {
                        length: response?.data?.totalElements,
                        pageIndex: currentPageIndex,
                        pageSize: response?.data?.pageable?.pageSize,
                    };
                }
            })
    }

    editCompany(company: CompanyModel) {
        this.modalService.openModalWindow(CompanyEditComponent, 'editCompany', {
            selectedCompany: company,
        }, {width: '1400px'}).pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.loadData()
        });
    }

    pageChanged(event: PageEvent): void {
        this.pagination.pageIndex = event.pageIndex;
        this.pagination.pageSize = event.pageSize;
        this.loadData();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    @Confirmable({
        title: 'confirmation',
        message: 'doYouWantToUnBlockCompany',
        icon: {
            show: true,
            name: "heroicons_outline:exclamation-circle",
            color: 'warn'
        }
    })
    unBlockCompany(company:CompanyModel) {
        this.companyService.patch<any>(null, null, `unblock-company/${company.id}`)
          .subscribe({
              next: (response) => {
               this.loadData()
              }
          });
    }
}
