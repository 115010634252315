<div class="flex flex-col flex-auto min-w-0 space-y-8">
    <div class="sm:overflow-x-auto shadow-md bg-white">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="shadow-lg">
            <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'name' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap">{{ element.companyName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="blockDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap"> {{ 'blockDate' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap capitalize">{{ element.blockDate | date:'dd.MM.yyyy' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="typeOfCompany">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'typeOfCompany' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap">{{ element.typeOfCompany | transloco }}
                </td>
            </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="py-2 px-4 border-b whitespace-nowrap">{{ 'actions' | transloco }}
            </th>
            <td mat-cell *matCellDef="let element"
                class="py-2 px-4 border-b whitespace-nowrap">
              <mat-icon   svgIcon="lock_open" (click)="unBlockCompany(element)"
                        class="cursor-pointer ease-in-out hover:text-green-700 rounded-full hover:shadow-3xl hover:shadow-gray-400">
              </mat-icon>
            </td>
          </ng-container>
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions"
                    class="py-2 px-4 border-b whitespace-nowrap">&nbsp;
                </th>
                <td mat-cell *matCellDef="let element" class="py-2 px-4 border-b whitespace-nowrap">
                    <button mat-icon-button aria-label="expand row"
                            (click)="(expandedReason = expandedReason === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedReason === element">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="expandedReason !== element">keyboard_arrow_down</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"
                    class="py-2 px-4 bg-primary-50">
                    <div class="flex overflow-hidden transition-all duration-300"
                         [@detailExpand]="element == expandedReason ? 'expanded' : 'collapsed'">
                            <div class="w-full flex flex-col gap-3">
                                    <strong>{{ 'reason' | transloco }}:</strong>
                                    <p class="px-4 break-all">{{element.reason}}</p>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-primary-50"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="hover:bg-gray-100 active:bg-gray-200"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="h-0"></tr>
        </table>
        <mat-paginator
                [length]="pagination?.length"
                [pageSize]="pagination?.pageSize || 20"
                [pageIndex]="pagination?.pageIndex || 0"
                [pageSizeOptions]="[5, 10, 20, 100]"
                [showFirstLastButtons]="true"
                (page)="pageChanged($event)">
        </mat-paginator>
    </div>
    <div class="sticky left-0 bottom-0 bg-gray-100 z-50 pb-4">
        <div class="bg-primary-50 border-y border-primary-200 w-full p-4 flex items-center justify-between">
            <ng-container>
                <strong>{{ 'total' | transloco }} {{ pagination?.length }} {{ 'blockedCompanies' | transloco }}</strong>
            </ng-container>
        </div>
    </div>
</div>
