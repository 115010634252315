<div class="mt-4 sm:mt-0">
    <button mat-flat-button
            class="bg-primary text-white"
            (click)="fileInput.click()">
        <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg"
                  data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" aria-hidden="true" height="100%" width="100%"
                 preserveAspectRatio="xMidYMid meet" focusable="false">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
            </svg>
        </mat-icon>
        <span class="ml-2 mr-1">{{ 'uploadFile' | transloco }}</span>
    </button>
    <input type="file" #fileInput (change)="onFileSelected($event)" class="hidden">
</div>
<div *ngIf="companyFiles?.length > 0" class="ng-star-inserted">
    <div class="pt-5"><strong>{{ 'files' | transloco }}</strong></div>
    <div class="-m-2 mt-2 flex flex-wrap">
        <div *ngFor="let file of companyFiles"
             class="bg-card m-2 flex h-40 w-40 cursor-pointer flex-col rounded-2xl p-4 shadow">
            <a (click)="openEditFile(file)" class="flex flex-col h-full" target="_blank">
                <div class="flex h-24 items-center justify-center">
                    <div class="relative">
                        <mat-icon role="img"
                                  class="mat-icon notranslate text-hint opacity-50 icon-size-24 mat-icon-no-color"
                                  aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 aria-hidden="true" height="100%" width="100%"
                                 preserveAspectRatio="xMidYMid meet" focusable="false">
                                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z"></path>
                                <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z"></path>
                            </svg>
                        </mat-icon>
                        <div class="absolute bottom-0 left-0 rounded px-1.5 text-xs font-semibold leading-5 text-white"
                             [ngClass]="{
                                    'bg-amber-600': file.type.toLowerCase() === '.jpg' || file.type.toLowerCase() === '.jpeg' || file.type.toLowerCase() === '.png',
                                    'bg-red-600': file.type.toLowerCase() === '.pdf',
                                    'bg-blue-600': file.type.toLowerCase() === '.doc' || file.type.toLowerCase() === '.txt'|| file.type.toLowerCase() === '.docx',
                                    'bg-green-600': file.type.toLowerCase() === '.xls' || file.type.toLowerCase() === '.xlsx',
                                    'bg-gray-600' : file.type.toLowerCase() === '.zip',
                                 }">
                            {{ file.type.toUpperCase() }}
                        </div>
                    </div>
                </div>
                <div class="flex flex-auto flex-col justify-center text-center text-xs font-medium mt-2">
                    <div class="truncate">{{ file.name }}</div>
                </div>
            </a>
        </div>
    </div>
</div>