import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { AddressModel } from '../model/address.model';
import { HttpClient } from '@angular/common/http';
import { endpointConfig } from '../config/endpoint.config';
import { SnackbarService } from './snackbar/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends HttpBaseService<AddressModel> {

  constructor(http: HttpClient, _snackBarService: SnackbarService) {
    super(http, endpointConfig.address, _snackBarService);
  }
}
