/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    link: '/dashboard',
  },
  {
    id: 'myProductOnSale',
    title: 'My Product On Sale',
    type: 'collapsable',
    link: '/my-products',
    children: [
      {
        id: 'allProduct',
        title: 'All Product',
        type: 'basic',
        link: '/my-products/products',
      },
      {
        id: 'productsPending',
        title: 'Products Pending',
        type: 'basic',
        link: '/my-products/pending',
      },
      {
        id: 'rejected-returnList',
        title: 'Rejected & Return',
        type: 'basic',
        link: '/my-products/rejected-return',
      },
    ],
  },
  {
    id: 'mySoldProducts',
    title: 'My Sold Products',
    type: 'basic',
    link: '/my-sold-products/list',
  },
  {
    id: 'sell',
    title: 'Sell',
    type: 'basic',
    link: '/sell',
  },
  {
    id: 'createRequest',
    title: 'Create Request',
    type: 'basic',
    link: 'customer/create-request',
  },
  {
    id: 'productsOnSale',
    title: 'Products On Sale',
    type: 'basic',
    link: 'customer/products-on-sale',
  },
  {
    id: 'accountant',
    title: 'accounting',
    type: 'basic',
    link: 'acc/dashboard',
  },
];

export const sellerNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    link: '/dashboard',
  },
  {
    id: 'myProductOnSale',
    title: 'My Product On Sale',
    type: 'collapsable',
    link: '/my-products',
    children: [
      {
        id: 'allProduct',
        title: 'All Product',
        type: 'basic',
        link: '/my-products/products',
      },
      {
        id: 'productsPending',
        title: 'Products Pending',
        type: 'basic',
        link: '/my-products/pending',
      },
    ],
  },
  {
    id: 'mySoldProducts',
    title: 'My Sold Products',
    type: 'basic',
    link: '/my-sold-products/list',
  },
  {
    id: 'sell',
    title: 'Sell',
    type: 'basic',
    link: '/sell',
  },
];
export const customerNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    link: '/dashboard',
  },
  {
    id: 'productsOnSale',
    title: 'Products On Sale',
    type: 'basic',
    link: 'customer/products-on-sale',
  },
  {
    id: 'myProducts',
    title: 'My Products',
    type: 'collapsable',
    children: [
      {
        id: 'purchasedProducts',
        title: 'purchasedProducts',
        type: 'basic',
        link: 'customer/my-products/my-sold-products',
      },
      {
        id: 'paidProducts',
        title: 'Paid Products',
        type: 'basic',
        link: 'customer/my-products/my-paid-products',
      },
      {
        id: 'returnedProducts',
        title: 'returnedProducts',
        type: 'basic',
        link: 'customer/my-products/returned-products',
      },
    ],
  },
  {
    id: 'createRequest',
    title: 'Create Request',
    type: 'basic',
    link: 'customer/create-request',
  },
];
export const accNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    link: '/dashboard',
  },
];
export const qcNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    link: '/dashboard',
  },
  {
    id: 'myProductOnSale',
    title: 'My Product On Sale',
    type: 'collapsable',
    link: '/my-products',
    children: [
      {
        id: 'allProduct',
        title: 'All Product',
        type: 'basic',
        link: '/my-products/products',
      },
      {
        id: 'productsPending',
        title: 'Products Pending',
        type: 'basic',
        link: '/my-products/pending',
      },
      {
        id: 'rejected-returnList',
        title: 'Rejected & Return',
        type: 'basic',
        link: '/my-products/rejected-return',
      },
    ],
  },
  {
    id: 'mySoldProducts',
    title: 'My Sold Products',
    type: 'basic',
    link: '/my-sold-products/list',
  }
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
