export class BaseModel {
  created: Date;
  updated: Date;
  deleted: boolean;

  constructor(obj?: any) {
    this.bind(obj);
  }

  bind(obj: any) {
    this.created = obj?.created ? new Date(obj.created) : new Date();
    this.updated = obj?.updated ? new Date(obj.updated) : new Date();
    this.deleted = obj?.deleted ?? false;
  }
}
