export class StatusChangesModel {
  id: number;
  date: Date;
  status: string;
  userId: number;
  description: string;

  constructor(obj?: any) {
    if (obj) {
      this.bind(obj);
    }
  }

  bind(obj: any) {
    this.id = obj?.id;
    this.date = obj?.date ? new Date(obj.date) : null;
    this.status = obj?.status ?? null;
    this.userId = obj?.userId ?? null;
    this.description = obj?.description ?? null;
  }
}
