import { environment } from '../../../environments/environment';

export const endpointConfig = {
  auth: environment.apiUrl + '/auth/',
  user: environment.apiUrl + '/user/',
  address: environment.apiUrl + '/address/',
  company: environment.apiUrl + '/company/',
  termin: environment.apiUrl + '/termin/',
  withdraw: environment.apiUrl + '/withdraw/',
  request: environment.apiUrl + '/request/',
  product: environment.apiUrl + '/product/',
  route: environment.apiUrl + '/route/',
  notification: environment.apiUrl + '/notification/',
  voice: environment.apiUrl + '/voice/',
  companyFile: environment.apiUrl + '/company-file/'
};
