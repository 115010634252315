<mat-drawer-container autosize>
  <div class="sidenav-content">
    <div class="flex flex-col overflow-x-auto" [style.height]="'calc(100vh - 15vh)'">
      <div class="mb-10 w-full p-3">
        <app-company [companyId]="selectedCompany.id" class="mb-10"></app-company>
      </div>
      <div class="flex flex-col p-4 gap-4">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>{{ 'companyFiles' | transloco }}</mat-panel-title>
            </mat-expansion-panel-header>
            <app-company-files
              [companyFiles]="selectedCompany.companyFiles"
              [companyId]="selectedCompany.id">
            </app-company-files>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  <mat-drawer #drawer class="sidenav" mode="over" position="end" >
    <company-file-edit *ngIf="selectedFile$.value" [selectedFile$]="selectedFile$"></company-file-edit>
  </mat-drawer>
</mat-drawer-container>