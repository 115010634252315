import { Component, ComponentRef, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import {
  CdkPortalOutletAttachedRef,
  ComponentPortal,
} from "@angular/cdk/portal";
import { DialogModule } from "@angular/cdk/dialog";
import { TranslocoPipe } from "@ngneat/transloco";
import { SnackbarService } from "../../services/snackbar/snackbar.service";
import { Subject, take, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '../../../../@fuse/services/media-watcher';

@Component({
  selector: "app-base-modal",
  templateUrl: "./base-modal.component.html",
  styleUrls: ["./base-modal.component.scss"],
  standalone: true,
  imports: [MatIconModule, DialogModule, TranslocoPipe],
})
export class BaseModalComponent implements OnInit {
  portal: ComponentPortal<any>;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _fuseMediaWatcherService:FuseMediaWatcherService,
    private dialogRef: MatDialogRef<BaseModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private _snackBarService: SnackbarService,
  ) {}

  fullscreen() {
    this.dialogRef.addPanelClass("full-width-dialog");
    this.dialogRef.removePanelClass("minimize-dialog");
    this.dialogRef.updateSize("100%", "100%");
    this.dialogRef.updatePosition({ top: "0", left: "0" });
  }

  minimize() {
    this.dialogRef.addPanelClass("minimize-dialog");
    this.dialogRef.removePanelClass("full-width-dialog");
    this.dialogRef.updateSize(this.data?.width || "1400px", "auto");
    this.dialogRef.updatePosition({});
    this.dialogRef.addPanelClass("mat-dialog-panel");
  }
  loadSubCompInputs(componentRef: CdkPortalOutletAttachedRef) {
    if (componentRef instanceof ComponentRef) {
      const componentInstance = componentRef.instance;
      if (this.data.subData) {
        Object.keys(this.data.subData).forEach((key) => {
          componentInstance[key] = this.data.subData[key];
        });
      }
    } else {
      this._snackBarService.openSnackBar(
        { title: "error", message: "invalidComponentReference" },
        "warn",
      );
    }
  }
  ngOnInit(): void {
    this.portal = new ComponentPortal(this.data.component);
    this.minimize();
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(take(1),takeUntil(this._unsubscribeAll))
      .subscribe(({matchingAliases}) =>
      {
        if(!matchingAliases.includes('md')){
          this.fullscreen();
        }else{
          this.minimize()
        }
      });
  }
}
