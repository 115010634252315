<div class="flex flex-col flex-auto p-6 md:p-8">
    <div class="flex items-center justify-end">
        <a
                mat-icon-button
                (click)="closeDrawer()">
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </a>
    </div>
    <div class="mt-8 aspect-[9/6]">
        <div class="flex items-center justify-center h-full border rounded-lg bg-gray-50 dark:bg-card w-[25vw]">
            <ng-container *ngIf="selectedFile$.value?.type === '.png' || selectedFile$.value?.type === '.jpeg' || selectedFile$.value?.type === '.jpg'">
                <img
                        class="text-hint"
                        [src]="environment.apiUrl+'/'+selectedFile$.value?.path">
            </ng-container>
            <ng-container *ngIf="selectedFile$.value?.type !== '.png' && selectedFile$.value?.type !== '.jpeg' && selectedFile$.value?.type !== '.jpg'">
                <mat-icon
                        class="icon-size-24 text-hint"
                        [svgIcon]="'heroicons_outline:document'"></mat-icon>
            </ng-container>
        </div>
    </div>
    <div class="flex flex-col items-start mt-8" *ngIf="selectedFile$.value">
        <div class="text-xl font-medium">{{ selectedFile$?.value?.name }}</div>
        <ng-container *ngIf="selectedFile$?.value?.type">
            <div
                    class="mt-1 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                    [class.bg-indigo-600]="selectedFile$?.value?.type === 'folder'"
                    [class.bg-red-600]="selectedFile$?.value?.type?.toUpperCase() === 'PDF'"
                    [class.bg-blue-600]="selectedFile$?.value?.type?.toUpperCase() === 'DOC'"
                    [class.bg-green-600]="selectedFile$?.value?.type?.toUpperCase() === 'XLS'"
                    [class.bg-gray-600]="selectedFile$?.value?.type?.toUpperCase() === 'TXT'"
                    [class.bg-amber-600]="selectedFile$?.value?.type?.toUpperCase() === 'JPG'">
                {{ selectedFile$?.value?.type?.toUpperCase() }}
            </div>
        </ng-container>
    </div>
    <div class="text-lg font-medium mt-8" *ngIf="selectedFile$.value">{{ 'information' | transloco }}</div>
    <div class="flex flex-col mt-4 border-t border-b divide-y font-medium" *ngIf="selectedFile$.value">
        <div class="flex items-center justify-between py-3">
            <div class="text-secondary">{{ 'createdBy' | transloco }}</div>
            <div>{{ selectedFile$?.value?.createdBy }}</div>
        </div>
        <div class="flex items-center justify-between py-3">
            <div class="text-secondary">{{ 'createdAt' | transloco }}</div>
            <div>{{ selectedFile$?.value?.created | date: 'dd.MM.yyyy HH:mm'  }}</div>
        </div>
        <div class="flex items-center justify-between py-3">
            <div class="text-secondary">{{ 'modifiedAt' | transloco }}</div>
            <div>{{ selectedFile$?.value?.updated | date: 'dd.MM.yyyy HH:mm' }}</div>
        </div>
        <div class="flex items-center justify-between py-3">
            <div class="text-secondary">{{ 'size' | transloco }}</div>
            <div>{{ selectedFile$?.value?.size }}</div>
        </div>
    </div>
    <div class="flex items-center justify-between mt-8" *ngIf="selectedFile$.value">
        <div class="text-lg font-medium">{{ 'description' | transloco }}</div>
        <button mat-icon-button (click)="updateDescription()">
            <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'feather:save'"></mat-icon>
        </button>
    </div>
    <div class="flex mt-2 border-t" *ngIf="selectedFile$.value">
        <div class="py-3 w-full">
            <ng-container>
                <textarea class="italic text-secondary p-2 w-full h-full bg-gray-100 overflow-y-auto resize rounded-2xl" rows="2"
                          [(ngModel)]="selectedFile$.value.description"
                          placeholder="{{ 'clickHereToAddADescription' | transloco }}"
                          *ngIf="selectedFile$.value"
                ></textarea>
            </ng-container>
        </div>

    </div>
    <div class="grid grid-cols-2 gap-4 w-full mt-8" *ngIf="selectedFile$.value">
        <button
                class="flex-auto"
                mat-flat-button
                [color]="'primary'"
                (click)="downloadFile()">
            {{ 'download' | transloco }}
        </button>
        <button
                class="flex-auto"
                mat-stroked-button
                (click)="deleteFile()">
            {{ 'delete' | transloco }}
        </button>
    </div>
</div>
