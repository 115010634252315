import {AddressModel} from './address.model';
import {BuildModel} from './build.model';
import {BaseModel} from './base.model';

export class OwnerModel extends BaseModel {
    id: number;
    name: string;
    age: number;
    telPhone: string;
    mobilePhone: string;
    email: string;
    financialStatus: string;

    constructor(obj?: any) {
        super();
        if (obj) {
            this.bind(obj);
        }
    }

    bind(obj: any) {
        this.id = obj?.id;
        this.name = obj?.name;
        this.age = obj?.age;
        this.telPhone = obj?.telPhone;
        this.mobilePhone = obj?.mobilePhone;
        this.email = obj?.email;
        this.financialStatus = obj?.financialStatus;

        return this;
    }
}
