import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {TranslocoPipe} from "@ngneat/transloco";
import {MatSelect} from "@angular/material/select";
import {AsyncPipe, NgClass, NgForOf} from "@angular/common";
import {MatCheckbox} from "@angular/material/checkbox";
import {CompanyModel} from "../../../../shared/model/company.model";
import {BehaviorSubject, debounceTime, distinctUntilChanged, Subject, takeUntil} from "rxjs";
import {CompanyService} from "../../../../shared/services/company.service";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {GenericResponseModel} from "../../../../shared/model/generic.model";
import {PaginateResponse} from "../../../../shared/model/paginate.type";
import {SnackbarService} from "../../../../shared/services/snackbar/snackbar.service";
import {Confirmable} from "../../../../shared/decorators/confirmable.decorator";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";

@Component({
    selector: 'app-block-company-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormField,
        MatOption,
        TranslocoPipe,
        MatSelect,
        NgForOf,
        MatCheckbox,
        MatInput,
        MatButton,
        AsyncPipe,
        NgClass
    ],
    templateUrl: './block-company-form.component.html',
})
export class BlockCompanyFormComponent implements OnInit {
    blockForm: FormGroup;
    companies$: BehaviorSubject<CompanyModel[]> = new BehaviorSubject<CompanyModel[]>([]);
    @Input() refreshForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private fb: FormBuilder, private companyService: CompanyService, private snackbarService: SnackbarService, private confirmationService: FuseConfirmationService) {
        this.blockForm = this.fb.group({
            company: ['', Validators.required],
            filter: [''],
            blockPermanently: [false],
            deleteMembership: [false],
            reason: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        this.loadData();
        this.refreshForm$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            if (value) {
                this.loadData();
                this.refreshForm$.next(false);
            }
        });
        this.blockForm.controls['filter'].valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this._unsubscribeAll))
            .subscribe(value => this.loadData(value))
    }

    loadData(query?: string): void {
        this.companyService
            .get<GenericResponseModel<PaginateResponse<CompanyModel>>>(`filter`, {filter: query ? query : ''})
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.companies$.next(response.data['content'].map(x => new CompanyModel(x)));
                },
            });
    }

    onSubmit(): void {
        const payload = this.blockForm.value;
        if (payload.blockPermanently) {
            this.onBlockSubmit();
        }
        else if(payload.deleteMembership){
            this.onDeleteMembershipSubmit();
        }
    }

    @Confirmable({
        title: 'confirmation',
        message: 'doYouWantToConfirmTheBlockOperation',
        icon: {
            show: true,
            name: "heroicons_outline:exclamation-circle",
            color: 'warn'
        }
    })
    onBlockSubmit(): void {
        if (this.blockForm.valid) {
            const payload = this.blockForm.value;
            this.companyService.post(payload.reason, `block-company/` + payload.company).subscribe({
                next: () => {
                    this.snackbarService.openSnackBar(
                        { title: 'success', message: 'blockingOperationWasSuccessfullyCompleted' },
                        'success',
                    );
                    this.refreshForm$.next(true);
                },
            });
        }
        this.blockForm.reset();
    }

    @Confirmable({
        title: 'confirmation',
        message: 'doYouWantToConfirmTheMembershipDeletion',
        icon: {
            show: true,
            name: "heroicons_outline:exclamation-circle",
            color: 'warn'
        }
    })
    onDeleteMembershipSubmit(): void {
        if (this.blockForm.valid) {
            const payload = this.blockForm.value;
            this.companyService.patch(payload.company, payload.reason, `cancel-company-membership/`).subscribe({
                next: () => {
                    this.snackbarService.openSnackBar(
                        { title: 'success', message: 'membershipDeletionWasSuccessfullyCompleted' },
                        'success',
                    );
                    this.refreshForm$.next(true);
                },
            });
        }
        this.blockForm.reset();
    }
}
