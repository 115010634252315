import { CompanyModel } from './company.model';
import { BaseModel } from './base.model';
import { OwnerModel } from './owner.model';
import { STATUS } from './status.enum';
import { StatusChangesModel } from './status-changes.model';
import { VoiceModel } from './voice.model';
import {AddressModel} from "./address.model";
import {BuildModel} from "./build.model";


export class TerminModel extends BaseModel {
  id: number;
  date: Date;
  terminDateTime: Date;
  lockedBy: string;
  lockedAt: Date;
  interviewer: string;
  interviewee: string;
  numberOfHouseHolds: number;
  interviewerNotes: string;
  qcNotes: string;
  mapLink: string;
  voices: VoiceModel[];
  seller: CompanyModel;
  customer: CompanyModel;
  owner: OwnerModel;
  status: typeof STATUS | null;
  address: AddressModel | null;
  build: BuildModel | null;
  heatPump: string;
  price: number;
  statusChanges: StatusChangesModel[];

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.bind(obj);
    }
  }

  bind(obj: any) {
    this.id = obj?.id;
    this.date = obj?.date ? new Date(obj.date) : null;
    this.terminDateTime = obj?.terminDateTime ? new Date(obj.terminDateTime) : null;
    this.lockedBy = obj?.lockedBy;
    this.lockedAt = obj?.lockedAt ? new Date(obj.lockedAt) : null;
    this.interviewer = obj?.interviewer;
    this.interviewee = obj?.interviewee;
    this.numberOfHouseHolds = obj?.numberOfHouseHolds;
    this.interviewerNotes = obj?.interviewerNotes;
    this.qcNotes = obj?.qcNotes;
    this.mapLink = obj?.mapLink;
    this.voices = obj?.voices ? obj.voices.map(x => new VoiceModel(x)) : null;
    this.seller = obj?.seller ? new CompanyModel(obj.seller) : null;
    this.customer = obj?.customer ? new CompanyModel(obj.customer) : null;
    this.owner = obj?.owner ? new OwnerModel(obj.owner) : null;
    this.status = obj?.status ?? null;
    if (Array.isArray(obj?.statusChanges)) {
      this.statusChanges = obj.statusChanges.map((statusChanges: any) => new StatusChangesModel(statusChanges));
    } else {
      this.statusChanges = [];
    }
    this.address = obj?.address ? new AddressModel(obj.address) : null;
    this.build = obj?.build ? new BuildModel(obj.build) : null;
    this.heatPump = obj?.heatPump;
    this.price = obj?.price;

    return this;
  }
}
