<mat-tab-group animationDuration="0ms" class="p-0" mat-stretch-tabs="false" mat-align-tabs="start"
               style="max-height: calc(100vh - 15.5rem);"
               (selectedTabChange)="tabChanged($event)">
  <mat-tab [label]="'blockCompany' | transloco">
    <app-block-company-form [refreshForm$]="refreshForm$">
    </app-block-company-form>
  </mat-tab>
  <mat-tab [label]="'blockedList' | transloco">
    <app-block-company-list [refreshList$]="refreshList$" [refreshMainTab$]="refreshMainTab$">
    </app-block-company-list>
  </mat-tab>
</mat-tab-group>
