import { BaseModel } from './base.model';

export class FileModel extends BaseModel{
    id: number;
    type: string;
    name: string;
    path: string;

    constructor(obj?: any) {
        super()
        if (obj) {
            this.bind(obj);
        }
    }

    bind(obj: any) {
        super.bind(obj)
        this.id = obj?.id;
        this.type = obj?.type;
        this.name = obj?.name;
        this.path = obj?.path + "?token=" + localStorage.getItem("accessToken");
        return this;
    }
}
