import { Component, inject, Inject, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoPipe } from "@ngneat/transloco";
import { fuseAnimations } from "../../../../@fuse/animations";
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: "app-snackbar",
  standalone: true,
  imports: [CommonModule, TranslocoPipe, MatProgressBar, MatIcon],
  templateUrl: "./snackbar.component.html",
  styleUrl: "./snackbar.component.scss",
  animations: fuseAnimations,
})
export class SnackbarComponent implements OnInit{
  progress = 100;
  intervalId: any;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
  public _snackBar = inject(MatSnackBar);

  ngOnInit() {
    this.startProgress();
  }
  startProgress() {
    const stepTime = 50;
    const decrementPerStep = 100 / (this.data.duration / stepTime);

    this.intervalId = setInterval(() => {
      this.progress -= decrementPerStep;
      if (this.progress <= 0) {
        this.progress = 0;
        clearInterval(this.intervalId);
      }
    }, stepTime);
  }
}
