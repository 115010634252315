<div class="p-4 space-y-4">
    <h2 class="text-xl font-bold">{{ title | transloco }}</h2>
    <div class="flex flex-wrap gap-2">
        <mat-chip-set role="list">
            <mat-chip role="listitem" *ngFor="let product of selectedProducts" class="bg-white text-lightslategray-500 border-2 border-slate-300 text-white">
                {{ product.name }} {{product.nameSuffix | transloco}}
            </mat-chip>
        </mat-chip-set>
    </div>
    <form [formGroup]="withdrawForm" (ngSubmit)="onSubmit()">
        <div class="w-full">
            <textarea matInput formControlName="withdrawReason" rows="10" class="w-full bg-white p-2 border rounded"></textarea>
        </div>
        <div class="flex justify-end py-6">
            <button mat-flat-button color="primary"
                    class="rounded-full text-white bg-gray-300 cursor-not-allowed "
                    [ngClass]="{
                        'bg-red-800 cursor-pointer': withdrawForm.valid
                    }"
                    type="submit" [disabled]="!withdrawForm.valid">
                {{ buttonLabel | transloco }}
            </button>
        </div>
    </form>
</div>
