import {Component, Input} from '@angular/core';
import {STATUS} from "../../model/status.enum";
import {NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault} from "@angular/common";
import {TranslocoPipe} from "@ngneat/transloco";

@Component({
  selector: 'app-product-status-selector',
  standalone: true,
  imports: [
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    TranslocoPipe
  ],
  templateUrl: './product-status-selector.component.html',
})
export class ProductStatusSelectorComponent {
  @Input() status: string | null;
  @Input() grayedOut: boolean = false;
  STATUS = STATUS;
}
