import { Component, Input } from '@angular/core';
import { ProductsComponent } from '../../../my-product/components/products/products.component';
import { STATUS } from '../../../../shared/model/status.enum';
import { CustomerFinancialStatusComponent } from '../customer-financial-status/customer-financial-status.component';

@Component({
  selector: 'app-return-product',
  standalone: true,
  imports: [
    ProductsComponent,
    CustomerFinancialStatusComponent,
  ],
  templateUrl: './return-product.component.html',
})
export class ReturnProductComponent {

  protected readonly STATUS = STATUS;
  @Input() status: string[] = [STATUS.SOLD];
}
