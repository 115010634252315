<div class="w-full">
    <form @fadeInLeft [formGroup]="addressForm">
        <div class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none capitalize">{{ 'address' | transloco }}</div>
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'city' | transloco }}</mat-label>
                    <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:home'"
                            matPrefix
                    ></mat-icon>
                    <input [formControlName]="'city'" matInput/>
                </mat-form-field>
            </div>
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'location' | transloco }}</mat-label>
                    <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:paper-airplane'"
                            matPrefix
                    ></mat-icon>
                    <input [formControlName]="'location'" matInput/>
                </mat-form-field>
            </div>
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'postCode' | transloco }}</mat-label>
                    <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:envelope'"
                            matPrefix
                    ></mat-icon>
                    <input [formControlName]="'postCode'" matInput/>
                </mat-form-field>
            </div>
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'street' | transloco }}</mat-label>
                    <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:map'"
                            matPrefix
                    ></mat-icon>
                    <input [formControlName]="'street'" matInput/>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="flex items-center justify-end mt-11">
        <button mat-stroked-button type="button" (click)="onReset()">
            {{ 'cancel' | transloco }}
        </button>
        <button
                (click)="onSubmit()"
                class="ml-4"
                mat-flat-button
                [disabled]="!addressForm.valid"
                type="button"
                [color]="'primary'"
        >
            {{ address?.id ? ('updateAddress' | transloco) : ('addAddress' | transloco) }}
        </button>
    </div>
</div>

