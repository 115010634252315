import { Injectable } from '@angular/core';
import { HttpBaseService } from "./http-base.service";
import { HttpClient } from "@angular/common/http";
import { endpointConfig } from "../config/endpoint.config";
import { SnackbarService } from './snackbar/snackbar.service';
import { ProductModel } from '../model/product.model';

@Injectable({
    providedIn: 'root'
})
export class ProductService extends HttpBaseService<ProductModel> {

    constructor(http: HttpClient, _snackBarService: SnackbarService) {
        super(http, endpointConfig.product, _snackBarService);
    }
}