<div
        class="flex flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden"
>
    <mat-drawer-container class="flex-auto sm:h-full">
        <!-- Drawer -->
        <mat-drawer
                class="sm:w-64 dark:bg-gray-900"
                [autoFocus]="false"
                [mode]="drawerMode"
                [opened]="drawerOpened"
                #drawer
        >
            <!-- Header -->
            <div class="flex items-center justify-between m-8 mr-6 sm:my-10">
                <!-- Title -->
                <div class="text-3xl font-extrabold tracking-tight leading-none">
                    {{ 'settings' | transloco }}
                </div>
                <!-- Close button -->
                <div class="lg:hidden">
                    <button mat-icon-button (click)="drawer.close()">
                        <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
            </div>
            <!-- Panel links -->
            <div class="flex flex-col divide-y border-t border-b">
                <ng-container *ngFor="let panel of panels; trackBy: trackByFn">
                    <div
                            class="flex px-8 py-5 cursor-pointer"
                            [ngClass]="{
              'hover:bg-gray-100 dark:hover:bg-hover':
                !selectedPanel || selectedPanel !== panel.id,
              'bg-primary-50 dark:bg-hover':
                selectedPanel && selectedPanel === panel.id
            }"
                            (click)="goToPanel(panel.id)"
                    >
                        <mat-icon
                                [ngClass]="{
                'text-hint': !selectedPanel || selectedPanel !== panel.id,
                'text-primary dark:text-primary-500':
                  selectedPanel && selectedPanel === panel.id
              }"
                                [svgIcon]="panel.icon"
                        ></mat-icon>
                        <div class="ml-3">
                            <div
                                    class="font-medium leading-6 capitalize"
                                    [ngClass]="{
                  'text-primary dark:text-primary-500':
                    selectedPanel && selectedPanel === panel.id
                }"
                            >
                                {{ panel.title | transloco }}
                            </div>
                            <div class="mt-0.5 text-secondary">
                                {{ panel.description }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-drawer>

        <!-- Drawer content -->
        <mat-drawer-content class="flex flex-col">
            <!-- Main -->
            <div class="flex-auto px-6 pt-9">
                <!-- Panel header -->
                <div class="flex items-center">
                    <!-- Drawer toggle -->
                    <button
                            class="lg:hidden -ml-2"
                            mat-icon-button
                            (click)="drawer.toggle()"
                    >
                        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                    </button>

                    <!-- Panel title -->
                    <div
                            class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none capitalize"
                    >
                        {{ getPanelInfo(selectedPanel)?.id | transloco }}

                    </div>
                </div>

                <!-- Load settings panel -->
                <div class="mt-8">
                    <ng-container [ngSwitch]="selectedPanel">
                        <!-- Profile -->
                        <ng-container *ngSwitchCase="'profile'">
                            <app-account></app-account>
                        </ng-container>
                        <!-- Software -->
                        <!--            <ng-container *ngSwitchCase="'system'" >-->
                        <!--              <app-software></app-software>-->
                        <!--            </ng-container>-->
                        <!-- Financial -->
                        <ng-container *ngSwitchCase="'financial'">
                            <app-financial></app-financial>
                        </ng-container>
                        <!-- Users & Auth -->
                        <ng-container *ngSwitchCase="'users&authorization'">
                            <app-users-authorization></app-users-authorization>
                        </ng-container>
                        <!-- Block -->
                        <ng-container *ngSwitchCase="'companies'">
                            <app-companies></app-companies>
                        </ng-container>
                        <!-- Return -->
                        <ng-container *ngSwitchCase="'return'">
                            <app-return-product></app-return-product>
                        </ng-container>
                        <!-- Notification -->
                        <ng-container *ngSwitchCase="'notifications'">
                            <app-notifications></app-notifications>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
