import {Component, Input, ViewChild} from '@angular/core';
import {CompanyComponent} from "../../../../shared/components/company/company.component";
import {CompanyModel} from "../../../../shared/model/company.model";
import {ReactiveFormsModule} from "@angular/forms";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle
} from "@angular/material/expansion";
import {MatFormField, MatLabel, MatPrefix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {TranslocoPipe} from "@ngneat/transloco";
import {AddressComponent} from "../../../../shared/components/address/address.component";
import {CompanyFilesComponent} from "../company-files/company-files.component";
import {FilesComponent} from "../../../../shared/components/files/files.component";
import {MatDrawer, MatDrawerContainer, MatSidenavModule} from '@angular/material/sidenav';
import {CompanyFileModel} from '../../../../shared/model/company-file.model';
import {BehaviorSubject} from 'rxjs';
import {
    CompanyFileEditComponent,
} from "./components/company-file-edit/company-file-edit.component";

@Component({
    selector: 'app-company-edit',
    standalone: true,
    imports: [
        CompanyComponent,
        AsyncPipe,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatFormField,
        MatIcon,
        MatInput,
        MatLabel,
        MatOption,
        MatPrefix,
        MatSelect,
        NgForOf,
        NgIf,
        ReactiveFormsModule,
        TranslocoPipe,
        AddressComponent,
        CompanyFilesComponent,
        FilesComponent,
        MatDrawerContainer,
        MatSidenavModule,
        CompanyFileEditComponent,
    ],
    templateUrl: './company-edit.component.html',
    styleUrl:'company-edit.component.scss'
})
export class CompanyEditComponent {
    @Input() selectedCompany: CompanyModel;
    selectedFile$:BehaviorSubject<CompanyFileModel> = new BehaviorSubject<CompanyFileModel>(null)
    @ViewChild('drawer', {static: true}) public matDrawer: MatDrawer;
}
