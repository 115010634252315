import { AddressModel } from './address.model';
import { CompanyModel } from './company.model';
import { BaseModel } from './base.model';

export class UserModel extends BaseModel {
  token: string | null;
  type: string;
  id: number;
  username: string;
  name: string;
  email: string;
  appeal: string | null;
  roles: string[] | null;
  avatar: string | null;
  address: AddressModel | null;
  company: CompanyModel | null;
  blocked: boolean;
  phoneNumber: string | null;

  constructor(obj?: any) {
    super();
    if (obj) {
      this.bind(obj);
    }
  }

  bind(obj: any) {
    super.bind(obj)
    this.id = obj?.id;
    this.token = obj?.token;
    this.username = obj?.username;
    this.name = obj?.name;
    this.email = obj?.email;
    this.avatar = obj?.avatar;
    this.appeal = obj?.appeal || null;
    this.address = new AddressModel(obj?.address) ?? null;
    this.company = new CompanyModel(obj?.company) ?? null;
    this.roles = obj?.roles ? obj.roles.map((role: string) => role) : [];
    this.blocked = obj?.blocked ?? false;

    this.phoneNumber = obj?.phoneNumber || null;
    return this;
  }
}
