import {Injectable} from '@angular/core';
import {HttpBaseService} from "./http-base.service";
import {CompanyModel} from "../model/company.model";
import {HttpClient} from "@angular/common/http";
import {endpointConfig} from "../config/endpoint.config";
import { SnackbarService } from './snackbar/snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends HttpBaseService<CompanyModel> {

    constructor(http: HttpClient,_snackBarService:SnackbarService) {
        super(http, endpointConfig.company,_snackBarService);
    }
}
