import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../../components/snackbar/snackbar.component";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}
  openSnackBar(
    data: { title: string; message: string },
    type: "warn" | "success" | "info"
  ) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: 5000,
      data: { ...data, type: type, duration:5000 },
    });
  }
}
