import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {CompanyFileModel} from "../../../../shared/model/company-file.model";
import {FilesComponent} from "../../../../shared/components/files/files.component";
import {CompanyService} from "../../../../shared/services/company.service";
import {MatIcon} from "@angular/material/icon";
import {MatButton} from "@angular/material/button";
import {TranslocoPipe} from "@ngneat/transloco";
import {NgClass, NgForOf, NgIf} from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { CompanyEditComponent } from '../company-edit/company-edit.component';

@Component({
    selector: 'app-company-files',
    standalone: true,
    imports: [
        FilesComponent,
        MatIcon,
        MatButton,
        TranslocoPipe,
        NgForOf,
        NgClass,
        NgIf,
    ],
    templateUrl: './company-files.component.html',
})
export class CompanyFilesComponent {
    @Input() companyFiles: CompanyFileModel[];
    @Input() companyId: number;
    constructor(private companyService: CompanyService,private companyEditComponent:CompanyEditComponent) {

    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            const formData: FormData = new FormData();
            formData.append('description', '');
            formData.append('files', file);

            this.companyService.patch<any>(null, formData, `${this.companyId}`)
                .subscribe({
                    next: (response) => {
                        this.companyFiles = response.data.map(img => new CompanyFileModel(img))
                    },
                    error: (error) => {
                        console.error('fileUploadError', error);
                    }
                });
        }
    }

  openEditFile(file: CompanyFileModel) {
    this.companyEditComponent.selectedFile$.next(file)
    this.companyEditComponent.matDrawer.toggle()
  }
  protected readonly environment = environment;

}
