import {Injectable} from '@angular/core';
import {HttpBaseService} from "./http-base.service";
import {HttpClient} from "@angular/common/http";
import {endpointConfig} from "../config/endpoint.config";
import {SnackbarService} from './snackbar/snackbar.service';
import {CompanyFileModel} from "../model/company-file.model";

@Injectable({
    providedIn: 'root'
})
export class CompanyFileService extends HttpBaseService<CompanyFileModel> {

    constructor(http: HttpClient,_snackBarService:SnackbarService) {
        super(http, endpointConfig.companyFile, _snackBarService);
    }
}