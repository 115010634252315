import {Component, OnInit} from '@angular/core';
import {BlockComponent} from "../block/block.component";
import {CompanyListComponent} from "../company-list/company-list.component";
import {MatTab, MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {TranslocoPipe} from "@ngneat/transloco";
import {SendNotificationComponent} from "../send-notification/send-notification.component";
import {NotificationsListComponent} from "../notifications-list/notifications-list.component";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-notifications',
    standalone: true,
    imports: [
        BlockComponent,
        CompanyListComponent,
        MatTab,
        MatTabGroup,
        TranslocoPipe,
        SendNotificationComponent,
        NotificationsListComponent
    ],
    templateUrl: './notifications.component.html',
    styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit {
    refreshSendNotifications$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    refreshNotificationsList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    ngOnInit() {
        this.refreshSendNotifications$.next(true);
    }

    tabChanged(event: MatTabChangeEvent) {
        if (event.index === 0) {
            this.refreshSendNotifications$.next(true);
        } else if (event.index === 1) {
            this.refreshNotificationsList$.next(true);
        }
    }
}
