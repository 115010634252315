import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onChildAdded, } from 'firebase/database';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private db;

  constructor() {
    const firebaseConfig = environment.firebaseConfig

    const app = initializeApp(firebaseConfig);
    this.db = getDatabase(app);
  }

  receiveUserMessages( userId: number, callback: (data: any) => void) {
    const userRef = ref(this.db, `notifications/${userId}`);
    onChildAdded(userRef, (snapshot) => {
      callback(snapshot.val());
    });
  }
}
