import { TextFieldModule } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgIf } from '@angular/common';
import { UserService } from '../../../../core/user/user.service';
import { FuseValidators } from '../../../../../@fuse/validators';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { TranslocoPipe } from '@ngneat/transloco';
import { UserModel } from '../../../../shared/model/user.model';
import { Subject, takeUntil } from 'rxjs';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { CompanyComponent } from '../../../../shared/components/company/company.component';
import { AddressModel } from '../../../../shared/model/address.model';
import { CompanyModel } from '../../../../shared/model/company.model';
import {SnackbarService} from "../../../../shared/services/snackbar/snackbar.service";
import {Confirmable} from "../../../../shared/decorators/confirmable.decorator";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import { AuthViewDirective } from '../../../../shared/directives/auth-view.directive';
import { ROLES } from '../../../../shared/model/user.roles.enum';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: fuseAnimations,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    TextFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    NgIf,
    TranslocoPipe,
    CompanyComponent,
    AddressComponent,
    AuthViewDirective,
  ],
})
export class SettingsAccountComponent implements OnInit, OnDestroy {
  accountForm: UntypedFormGroup;
  user: UserModel;
  _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _userService: UserService,
    private cdr: ChangeDetectorRef,
    private snackbarService: SnackbarService,
    private confirmationService: FuseConfirmationService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._userService.user$.subscribe((user) => {
      this.user = user;
      this.initForm();
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  initForm() {
    this.accountForm = this._formBuilder.group(
      {
        username: [this.user?.username, Validators.required],
        email: [this.user?.email, Validators.email],
        phoneNumber: [this.user?.phoneNumber],
        name: [this.user?.name, Validators.required],
        appeal: [this.user?.appeal],
        password: ['', [Validators.required, Validators.minLength(6)]],
        passwordConfirm: ['', [Validators.required, Validators.minLength(6)]],
      },
      { validators: FuseValidators.mustMatch('password', 'passwordConfirm') },
    );

    this.cdr.markForCheck();
  }
  @Confirmable({
    title: 'confirmation',
    message: 'doYouWantToUpdateYourPersonalInformation',
    icon: {
      show: true,
      name: "heroicons_outline:exclamation-circle",
      color: 'warn'
    }
  })
  onSubmit() {
    this._userService.update(this.user.id, this.accountForm.value).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        {  next : value => {
             this.accountForm.reset();
            this.initForm();
            this.snackbarService.openSnackBar( {title: 'success', message: 'updateProcessSuccessful'},
                'success',
            );
          },
          error : err => {
            this.snackbarService.openSnackBar(
                {title: 'error', message: 'updateFailed'},
                'warn',
            );
          },
    });
  }

  onUpdateAddress(address: AddressModel) {
    if(!this.user.address.id){
      delete this.accountForm.value['password'];
      delete this.accountForm.value['passwordConfirm']
      const updatedUser = {
        ...this.accountForm.value,
        address: address,
        company: this.user?.company?.id ? this.user.company : null,
      } as UserModel;
      this._userService.update(this.user.id, updatedUser)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: value => {
            this.snackbarService.openSnackBar(
              { title: 'success', message: 'updateProcessSuccessful' },
              'success',
            );
          },
          error: err => {
            this.snackbarService.openSnackBar(
              { title: 'error', message: 'updateFailed' },
              'warn',
            );
          },
        });
    }
  }

  onUpdateCompany(company: CompanyModel) {
    if(!this.user.company.id){
      delete this.accountForm.value['password'];
      delete this.accountForm.value['passwordConfirm']
      const updatedUser = {
        ...this.accountForm.value,
        company: company,
        address: this.user?.address?.id ? this.user.address : null,
      } as UserModel;
      this._userService.update(this.user.id, updatedUser)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe( {
        next : value =>{
          this.snackbarService.openSnackBar(
            {title: 'success', message: 'updateProcessSuccessful'},
            'success',
          );
        },
        error : err => {
          this.snackbarService.openSnackBar(
            {title: 'error', message: 'updateFailed'},
            'warn',
          );
        },
      });
    }
  }

  onReset() {
    this.accountForm.reset();
    this.accountForm.patchValue(this.user);
  }

  protected readonly ROLES = ROLES;
}
