<div class="flex flex-col flex-auto min-w-0 space-y-4" >
  <h2 class="text-xl font-semibold">
    <ng-container [ngSwitch]="status.toString()">
      <span *ngSwitchCase="'CUSTOMER_PAID,FINISH'">{{ 'paidPurchases' | transloco }}</span>
      <span *ngSwitchCase="'SOLD,SELLER_PAID'">{{ 'unpaidPurchases' | transloco }}</span>
    </ng-container>
  </h2>
  <div class="overflow-x-auto shadow-md bg-white" [style.height]="'calc(100vh - 18rem)'">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="w-full shadow-lg">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            class="py-2 px-4 border-b whitespace-nowrap"> {{ 'type' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"
            class="py-2 px-4 border-b whitespace-nowrap capitalize">{{ element.type | transloco }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            class="py-2 px-4 border-b whitespace-nowrap">{{ 'date' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"
            class="py-2 px-4 border-b whitespace-nowrap">{{ element.date | date:'dd.MM.yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            class="py-2 px-4 border-b whitespace-nowrap">{{ 'price' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element"
            class="py-2 px-4 border-b whitespace-nowrap">{{ element.price | currency : 'EUR' : 'symbol' :'1.2-2' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-primary-50"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
          class="hover:bg-gray-100 active:bg-gray-200"></tr>
    </table>
    <mat-paginator
      [length]="pagination?.length"
      [pageSize]="pagination?.pageSize || 20"
      [pageIndex]="pagination?.pageIndex || 0"
      [pageSizeOptions]="[5, 10, 20, 100]"
      [showFirstLastButtons]="true"
      (page)="pageChanged($event)">
    </mat-paginator>
  </div>
  <div class="sticky left-0 bottom-0 bg-gray-100 z-50 pb-2">
    <div class="bg-primary-50 border-y border-primary-200 w-full p-4 flex items-center justify-between">
      <strong> {{ 'total' | transloco }} {{ pagination?.length }} {{ 'products' | transloco }}</strong>
      <strong> {{ 'totalPrice' | transloco }} {{ totalPrice | currency : 'EUR' : 'symbol' :'1.2-2' }} </strong>
    </div>
  </div>
</div>