import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { SoftwareComponent } from '../../../modules/user/profile-settings/software/software.component';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatError, MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { TranslocoPipe } from '@ngneat/transloco';
import { Subject, takeUntil, tap } from 'rxjs';
import { AddressModel } from '../../model/address.model';
import { fuseAnimations } from '../../../../@fuse/animations';
import { AddressService } from '../../services/address.service';
import { GenericResponseModel } from '../../model/generic.model';
import {Confirmable} from "../../decorators/confirmable.decorator";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";

@Component({
  selector: 'app-address',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    SoftwareComponent, FormsModule, MatFormField, MatInput, MatLabel, MatPrefix, MatError, MatOption, MatSelect, MatSuffix, NgIf, ReactiveFormsModule, TranslocoPipe],
  templateUrl: './address.component.html',
  animations: fuseAnimations,
})
export class AddressComponent implements OnInit, OnDestroy {
  @Input() addressId: number;
  @Output() newAdress: EventEmitter<AddressModel> = new EventEmitter<AddressModel>();
  addressForm: UntypedFormGroup;
  address: AddressModel;
  _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private _addressService: AddressService,
    private confirmationService: FuseConfirmationService,
  ) {
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.initForm();
    if (this.addressId) {
      this._addressService.get<AddressModel>(this.addressId.toString()).pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
        this.address = value.data;
        this.addressForm.patchValue(value.data);
      });
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  initForm() {
    this.addressForm = this._formBuilder.group(
      {
        city: [this.address?.city],
        location: [this.address?.location],
        postCode: [this.address?.postCode],
        street: [this.address?.street],
      },
    );

    this.cdr.markForCheck();
  }
  @Confirmable({
    title: 'confirmation',
    message: 'doYouWantToUpdateAddressInformation',
    icon: {
      show: true,
      name: "heroicons_outline:exclamation-circle",
      color: 'warn'
    }
  })
  onUpdate() {
    this._addressService.update<AddressModel>(this.address.id, this.addressForm.value).pipe(takeUntil(this._unsubscribeAll), tap((value: GenericResponseModel<AddressModel>) => {
      this.newAdress.emit(value.data);
      this.address=value.data;
    })).subscribe();
  }

  onCreate() {
    this._addressService.post(this.addressForm.value).pipe(
      takeUntil(this._unsubscribeAll),
      tap((value: GenericResponseModel<AddressModel>) => {
        this.newAdress.emit(value.data);
        this.address = value.data;
      }),
    ).subscribe();
  }

  onReset() {
    this.addressForm.reset();
    this.addressForm.patchValue(this.address);
  }

  onSubmit() {
    if (this.address?.id) {
      this.onUpdate();
    } else {
      this.onCreate();
    }
  }
}
