import { take } from 'rxjs/operators';
import { FuseConfirmationConfig, FuseConfirmationService } from '../../../@fuse/services/confirmation';

export function Confirmable(options?: FuseConfirmationConfig) {

  return function(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {

    const originalMethod = descriptor.value;

    descriptor.value = function(...args: any[]) {

      const service: FuseConfirmationService = (this as any).confirmationService;

      if (service) {
        const dialogRef = service.open(options);
        if (dialogRef) {
          dialogRef.afterClosed().pipe(take(1)).subscribe((response) => {
            if (response === 'confirmed') {
              originalMethod.apply(this, args);
            }
          });
        }
      }
    };
    return descriptor;
  };
}
