export class AddressModel {

  id: number;
  postCode: string;
  location: string;
  city: string;
  street: string;

  constructor(obj?: any) {
    if (obj) {
      this.bind(obj);
    }
  }

  bind(obj: any) {
    this.id = obj?.id;
    this.postCode = obj?.postCode;
    this.location = obj?.location;
    this.city = obj?.city;
    this.street = obj?.street;
  }
}