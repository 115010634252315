import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {AsyncPipe, CommonModule, CurrencyPipe, DatePipe, NgForOf, NgIf} from "@angular/common";
import {MatButton, MatIconButton} from "@angular/material/button";
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource
} from "@angular/material/table";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {
    ProductStatusSelectorComponent
} from "../../../../shared/components/product-status-selector/product-status-selector.component";
import {TranslocoPipe} from "@ngneat/transloco";
import {CompanyService} from "../../../../shared/services/company.service";
import {BaseTableComponent} from "../../../../shared/components/base-table/base-table.component";
import {BehaviorSubject, takeUntil} from "rxjs";
import {BlockedCompanyModel} from "../../../../shared/model/blocked-company.model";
import {MatIcon} from "@angular/material/icon";
import {detailExpand} from "../../../../shared/animations/animations";
import {FormsModule} from "@angular/forms";
import { Confirmable } from '../../../../shared/decorators/confirmable.decorator';
import { CompanyModel } from '../../../../shared/model/company.model';
import { FuseConfirmationService } from '../../../../../@fuse/services/confirmation';

@Component({
    selector: 'app-block-company-list',
    standalone: true,
    imports: [
        AsyncPipe,
        CurrencyPipe,
        DatePipe,
        MatButton,
        MatCell,
        MatCellDef,
        MatCheckbox,
        MatColumnDef,
        MatHeaderCell,
        MatHeaderRow,
        MatHeaderRowDef,
        MatPaginator,
        MatRow,
        MatRowDef,
        MatSort,
        MatSortHeader,
        MatTable,
        NgIf,
        ProductStatusSelectorComponent,
        TranslocoPipe,
        MatHeaderCellDef,
        NgForOf,
        MatIcon,
        MatIconButton,
        CommonModule,
        FormsModule
    ],
    animations:[detailExpand],
    templateUrl: './block-company-list.component.html',
})
export class BlockCompanyListComponent extends BaseTableComponent implements AfterViewInit, OnInit {
    public dataSource: MatTableDataSource<BlockedCompanyModel> = new MatTableDataSource<BlockedCompanyModel>();
    blockedCompanies$: BehaviorSubject<BlockedCompanyModel[]> = new BehaviorSubject<BlockedCompanyModel[]>([]);
    @Input() refreshList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @Input() refreshMainTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    expandedReason: BlockedCompanyModel | null = null;
    public displayedColumns: string[] = [
        'companyName',
        'blockDate',
        'typeOfCompany',
        'actions',
        'expand'
    ];

    constructor(private companyService: CompanyService, private confirmationService: FuseConfirmationService,) {
        super();
    }

    ngOnInit(){
        this.refreshMainTab$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            if (value) {
                this.loadData();
                this.refreshMainTab$.next(false);
            }
        });
        this.refreshList$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            if (value) {
                this.loadData();
                this.refreshList$.next(false);
            }
        });
    }

    ngAfterViewInit() {
        this.pagination?.pageIndex ? this.pagination.pageIndex = 0 : ' ';
        this.dataSource.sort = this._sort;
        this._sort.active = 'id';
        this._sort.direction = 'desc';
        this._sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: () => {
                this.loadData();
            },
        });
        this.blockedCompanies$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (blockedCompanies) => {
                this.dataSource.data = blockedCompanies;
            }
        });
        this.loadData();
    }

    loadData(query?: string): void {
        this.companyService
            .getPaginate<BlockedCompanyModel>(`get-all-blocked`,{
                page: this.pagination?.pageIndex || 0,
                size: this._paginator?.pageSize || 20
            })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.blockedCompanies$.next(response.data.content.map((x) => new BlockedCompanyModel(x)));
                    this.cdr.markForCheck();
                    this.pagination = {
                        length: response?.data?.totalElements,
                        pageIndex: response?.data?.pageable?.pageNumber,
                        pageSize: response?.data?.pageable?.pageSize,
                    };
                },

            });
    }

    pageChanged(event: PageEvent): void {
        this.pagination.pageIndex = event.pageIndex;
        this.pagination.pageSize = event.pageSize;
        this.loadData();
    }

    @Confirmable({
        title: 'confirmation',
        message: 'doYouWantToUnBlockCompany',
        icon: {
            show: true,
            name: "heroicons_outline:exclamation-circle",
            color: 'warn'
        }
    })
    unBlockCompany(company:BlockedCompanyModel) {
        this.companyService.patch<any>(null, null, `unblock-company/${company.companyId}`)
          .subscribe({
              next: (response) => {
                  this.loadData()
              }
          });
    }

}
