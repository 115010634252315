import {Component, Input} from '@angular/core';
import {MatTab, MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {BlockCompanyFormComponent} from "../block-company-form/block-company-form.component";
import {BlockCompanyListComponent} from "../block-company-list/block-company-list.component";
import {BehaviorSubject} from "rxjs";
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'app-block',
  standalone: true,
  imports: [
    MatTab,
    MatTabGroup,
    BlockCompanyFormComponent,
    BlockCompanyListComponent,
    TranslocoPipe,
  ],
  templateUrl: './block.component.html',
  styleUrl: './block.component.scss'
})
export class BlockComponent {
  refreshForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() refreshMainTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  tabChanged(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.refreshForm$.next(true);
    } else if (event.index === 1) {
      this.refreshList$.next(true);
    }
  }
}
