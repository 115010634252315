import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgClass, NgFor, NgSwitch, NgSwitchCase} from '@angular/common';
import {MatDrawer, MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {Subject, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '../../../../@fuse/services/media-watcher';
import {SettingsAccountComponent} from './account/account.component';
import {SoftwareComponent} from './software/software.component';
import {UserService} from '../../../core/user/user.service';
import {TranslocoPipe} from '@ngneat/transloco';
import {FinancialComponent} from '../../customer/components/financial/financial.component';
import {BlockComponent} from '../../admin/components/block/block.component';
import {ReturnProductComponent} from '../../customer/components/return-product/return-product.component';
import {UsersAuthorization} from '../../admin/components/team/users-authorization';
import {CompaniesComponent} from '../../admin/components/companies/companies.component';
import {SendNotificationComponent} from "../../admin/components/send-notification/send-notification.component";
import {NotificationsComponent} from "../../admin/components/notifications/notifications.component";

@Component({
    selector: 'app-profile-settings',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        NgFor,
        NgClass,
        NgSwitch,
        NgSwitchCase,
        SettingsAccountComponent,
        SoftwareComponent,
        TranslocoPipe,
        FinancialComponent,
        BlockComponent,

        ReturnProductComponent,
        UsersAuthorization,
        CompaniesComponent,
        SendNotificationComponent,
        NotificationsComponent,
    ],
    templateUrl: './profile-settings.component.html',
})
export class ProfileSettingsComponent {
    @ViewChild('drawer') drawer: MatDrawer;
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    panels: any[] = [];
    selectedPanel: string = 'profile';
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _userService: UserService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Setup available panel
        this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            this.panels = [
                {
                    id: 'profile',
                    icon: 'heroicons_outline:user-circle',
                    title: value?.name,
                    description: value?.company?.name,
                },
                // {
                //   id: 'system',
                //   icon: 'settings_applications',
                //   title: 'system',
                //   description: 'system_desc',
                // },
            ];
            if (localStorage.getItem('userRoles').includes('ROLE_CUSTOMER')) {
                this.panels.push({
                    id: 'financial',
                    icon: 'heroicons_outline:currency-dollar',
                    title: 'financial',
                    description: '',
                });
                this.panels.push({
                    id: 'return',
                    icon: 'heroicons_outline:arrow-left-circle',
                    title: 'return',
                    description: '',
                });
            }
            if (localStorage.getItem('userRoles').includes('ROLE_ADMIN')) {
                this.panels.push(
                    {
                        id: 'users&authorization',
                        icon: 'heroicons_solid:user-group',
                        title: 'users&authorization',
                        description: '',
                    },
                    {
                        id: 'companies',
                        icon: 'heroicons_solid:squares-plus',
                        title: 'companies',
                        description: '',
                    },
                    {
                        id: 'notifications',
                        icon: 'heroicons_solid:bell',
                        title: 'notifications',
                        description: '',
                    }
                );
            }
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Navigate to the panel
     *
     * @param panel
     */
    goToPanel(panel: string): void {
        this.selectedPanel = panel;

        // Close the drawer on 'over' mode
        if (this.drawerMode === 'over') {
            this.drawer.close();
        }
    }

    /**
     * Get the details of the panel
     *
     * @param id
     */
    getPanelInfo(id: string): any {
        return this.panels.find((panel) => panel.id === id);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
