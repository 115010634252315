import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { MatChip, MatChipSet } from '@angular/material/chips';
import { TranslocoPipe } from '@ngneat/transloco';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { NgClass, NgForOf } from '@angular/common';
import { ProductModel } from '../../model/product.model';
import { ProductService } from '../../services/product.service';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { SnackbarService } from '../../services/snackbar/snackbar.service';
import {Confirmable} from "../../decorators/confirmable.decorator";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";

@Component({
  selector: 'app-withdraw-modal',
  standalone: true,
  imports: [
    MatChip,
    TranslocoPipe,
    FormsModule,
    MatFormField,
    MatInput,
    MatButton,
    NgForOf,
    MatChipSet,
    ReactiveFormsModule,
    NgClass,
  ],
  templateUrl: './status-change.component.html',
  styleUrl: './status-change.component.scss',
})
export class StatusChangeComponent implements OnInit, OnDestroy {
  withdrawForm: FormGroup;
  selectedProducts: ProductModel[] = [];
  @Input() status: string;
  @Input() buttonLabel: string = 'changes';
  @Input() title: string = 'statusChanges';
  public _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private productService: ProductService, private snackbarService: SnackbarService,private dialogRef: MatDialogRef<BaseModalComponent>, private confirmationService: FuseConfirmationService) {
    this.dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.selectedProducts = [];
    });
  }

  ngOnInit(): void {
    this.withdrawForm = this.fb.group({
      withdrawReason: ['', Validators.required],
    });
  }

  @Confirmable(
      {
        title: 'confirmation',
        message: 'areYouSureYouWantToConfirm',
        icon: {
          show: true,
          name: 'mat_outline:replay',
          color: 'success',
        },
      },
  )
  onSubmit() {
    if (this.withdrawForm.valid) {
      this.productService.update(null, {
        products: this.selectedProducts,
        description: this.withdrawForm.value.withdrawReason,
      }, this.status).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        response => {
          this.dialogRef.close();
          this.snackbarService.openSnackBar({title: 'info', message: 'statusHasBeenSuccessfullyUpdated'},
            'info',
          );
        },
      );
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
