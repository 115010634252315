export class ProductModel {
  id: number;
  name: string;
  type: string;
  date: Date;
  appointmentDate: Date;
  status: string;
  nameSuffix: string;
  price: number;
  customer: string;
  seller: string;
  sellerId: number;
  productId: number;

  constructor(obj?: any) {
    if (obj) {
      this.bind(obj);
    }
  }

  bind(obj: any) {
    this.id = obj?.id;
    this.name = obj?.name;
    this.type = obj?.type;
    this.date = obj?.date ? new Date(obj.date) : null;
    this.appointmentDate = obj?.appointmentDate ? new Date(obj.appointmentDate) : null;
    this.status = obj?.status ?? null;
    this.nameSuffix = obj?.nameSuffix;
    this.price = obj?.price;
    this.customer = obj?.customer;
    this.seller = obj?.seller;
    this.sellerId = obj?.sellerId;
    this.productId = obj?.productId;
    return this;
  }
}
