import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { AsyncPipe, CurrencyPipe, DatePipe, NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { ProductService } from '../../../../shared/services/product.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSort, MatSortHeader, MatSortModule } from '@angular/material/sort';
import {
  ProductStatusSelectorComponent,
} from '../../../../shared/components/product-status-selector/product-status-selector.component';
import { BaseTableComponent } from '../../../../shared/components/base-table/base-table.component';
import { ProductModel } from '../../../../shared/model/product.model';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-customer-financial-status',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    MatCard,
    MatCardContent,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDivider,
    MatFormField,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatInput,
    MatPaginator,
    MatRow,
    MatRowDef,
    MatSlideToggle,
    MatSuffix,
    MatTable,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    TranslocoPipe,
    CurrencyPipe,
    MatCheckbox,
    MatSort,
    MatSortHeader,
    MatSortModule,
    ProductStatusSelectorComponent,
    MatHeaderCellDef,
    NgSwitch,
    NgSwitchCase,
  ],
  templateUrl: './customer-financial-status.component.html',
})
export class CustomerFinancialStatusComponent extends BaseTableComponent implements AfterViewInit, OnDestroy{
  public dataSource: MatTableDataSource<ProductModel> = new MatTableDataSource<ProductModel>();
  @Input() status: string[] = [];
  public totalPrice: number;
  public displayedColumns: string[] = [
    'type',
    'date',
    'price',
  ];


  constructor(private productService: ProductService) {
    super();
  }

  ngAfterViewInit(): void {
    this.pagination?.pageIndex ? this.pagination.pageIndex = 0 : ' ';
    this.dataSource.sort = this._sort;
    this._sort.active = 'id';
    this._sort.direction = 'desc';
    this._sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: () => {
        this.loadData();
      },
    });
    this.loadData();
  }

  loadData(query?: string): void {
    this.productService
      .getPaginate<ProductModel>(
        'get-products',
        {
          ...(this._sort?.active ? {sort: this._sort?.active} : {}),
          ...(this._sort?.direction ? {sortType: this._sort?.direction} : {}),
          status: this.status,
          page: this._paginator?.pageIndex || 0,
          size: this._paginator?.pageSize || 20,
          ...(query ? {filter: query} : {}),
        },
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        this.dataSource.data = value?.data?.content?.map(
          (val) => new ProductModel(val),
        );
        this.cdr.markForCheck();
        this.pagination = {
          length: value?.data?.totalElements,
          pageIndex: value?.data?.pageable?.pageNumber,
          pageSize: value?.data?.pageable?.pageSize,
        };
      });
  }

  pageChanged(event: PageEvent): void {
    this.pagination.pageIndex = event.pageIndex;
    this.pagination.pageSize = event.pageSize;
    this.loadData();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

}