import {BaseModel} from './base.model';

export class BuildModel extends BaseModel {
    id: number;
    buildYear: string;
    powerConsumption: string;
    roofDirection: string;
    roofSpace: string;
    roofType: string;
    heatingType: string;
    roofMaterial: string;
    roofStatus: string;

    constructor(obj?: any) {
        super();
        if (obj) {
            this.bind(obj);
        }
    }

    bind(obj: any) {
        this.id = obj?.id;
        this.buildYear = obj?.buildYear ? (typeof obj.buildYear === 'string' ? obj.buildYear : new Date(obj.buildYear).getFullYear()) : null;
        this.powerConsumption = obj?.powerConsumption;
        this.roofDirection = obj?.roofDirection;
        this.roofSpace = obj?.roofSpace;
        this.roofType = obj?.roofType;
        this.heatingType = obj?.heatingType;
        this.roofMaterial = obj?.roofMaterial;
        this.roofStatus = obj?.roofStatus;
        return this;
    }
}
