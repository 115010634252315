import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {
  AsyncPipe,
  CurrencyPipe,
  DatePipe,
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { ProductModel } from '../../../../shared/model/product.model';
import { MatRadioButton } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { detailExpand } from '../../../../shared/animations/animations';
import { TerminService } from '../../../../shared/services/termin.service';
import { UserService } from '../../../../core/user/user.service';
import { UserModel } from '../../../../shared/model/user.model';
import { TranslocoPipe } from '@ngneat/transloco';
import {
  ProductStatusSelectorComponent,
} from '../../../../shared/components/product-status-selector/product-status-selector.component';
import { ModalService } from '../../../../shared/services/modal.service';
import { StatusChangeComponent } from '../../../../shared/components/status-change/status-change.component';
import { Router } from '@angular/router';
import { BaseTableComponent } from '../../../../shared/components/base-table/base-table.component';
import { ProductService } from '../../../../shared/services/product.service';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {STATUS} from "../../../../shared/model/status.enum";
import {ROLES} from "../../../../shared/model/user.roles.enum";

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [MatTableModule, MatButtonModule, MatIconModule, MatPaginatorModule, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, DatePipe, NgClass, MatRadioButton, FormsModule, MatCheckbox, ProductStatusSelectorComponent, TranslocoPipe, MatSort,
    MatSortModule, AsyncPipe, CurrencyPipe],
  animations: [detailExpand],
  templateUrl: './products.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsComponent extends BaseTableComponent implements AfterViewInit, OnInit {
  public dataSource: MatTableDataSource<ProductModel> = new MatTableDataSource<ProductModel>();

  public displayedColumns: string[] = [
    'name',
    'type',
    'appointmentDate',
    'select',
  ];
  public expandedElement: ProductModel | null;
  public _unsubscribeAll: Subject<any> = new Subject<any>();
  @Input({ required: false }) products$: BehaviorSubject<ProductModel[]> = new BehaviorSubject<ProductModel[]>([]);
  public selectedProducts: ProductModel[] = [];
  public expandedRoute: ProductModel[] = [];
  disabledProducts: ProductModel[] = [];
  public selectedType: string | null = null;
  user: UserModel;
  isSeller: boolean = false;
  @Input() status: string[] = [];
  @Input() mode: string='';
  constructor(private _userService: UserService, private productService: ProductService, private terminService: TerminService, private modalService: ModalService, private router: Router) {
    super();
  }

  ngOnInit() {
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => this.user = new UserModel(value));
    const userRoles = localStorage.getItem('userRoles');
    if (userRoles && userRoles.includes(ROLES.ROLE_SELLER)) {
      this.isSeller = true;
    }
    if(this.mode === 'return'){
      this.displayedColumns = this.displayedColumns.filter(column => column !== "select");
      this.displayedColumns.push('price', 'select');
    } else {
      this.displayedColumns = this.displayedColumns.filter(column => column !== "select");
      if (!this.isSeller) {
        this.displayedColumns.push('status', 'select', 'expand');
      } else {
        this.displayedColumns.push('status', 'expand');
      }
    }
    if(this.mode === 'pendingProduct') {
      const columnsToRemove = ['select', 'status', 'appointmentDate', 'expand'];
      this.displayedColumns = this.displayedColumns.filter(column => !columnsToRemove.includes(column));
      this.displayedColumns.push('seller', 'appointmentDate', 'expand');
    }
  }

  ngAfterViewInit(): void {
    this.pagination?.pageIndex ? this.pagination.pageIndex = 0 : ' ';
    this.dataSource.sort = this._sort;
    this._sort.active = 'id';
    this._sort.direction = 'desc';
    this._sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: () => {
        this.loadData();
      },
    });
    this.loadData();
  }

  loadData(query?: string): void {
    this.productService
      .getPaginate<ProductModel>(
        'get-products',
        {
          ...(this._sort?.active ? { sort: this._sort?.active } : {}),
          ...(this._sort?.direction ? { sortType: this._sort?.direction } : {}),
          status: this.status,
          page: this._paginator?.pageIndex || 0,
          size: this._paginator?.pageSize || 20,
        },
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next:(value)=>{
          this.dataSource.data = value.data.content.map(
            (val) => new ProductModel(val),
          );
          this.cdr.markForCheck();
          this.pagination = {
            length: value.data.totalElements,
            pageIndex: value.data.pageable.pageNumber,
            pageSize: value.data.pageable.pageSize,
          };
        },
        error: (e) => {
          this.dataSource.data = null;
        }
        }
      );
  }

  onRowClick(element: ProductModel, event: MouseEvent) {
    if (!(event.target instanceof HTMLElement && event.target.closest('mat-checkbox'))) {
      this.viewDetails(element);
    }
  }

  viewDetails(product: ProductModel) {
    const routeType = product.type.toLowerCase();
    this.router.navigate([`my-products/products/${routeType}`, product.productId], { state: { product: product } });
  }

  selectedRouteTermins(routeId: number) {
    this.expandedRoute = [];
    this.terminService.get<ProductModel[]>(`get-by-route/` + routeId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response) => {
          this.expandedRoute = response.data.map(x => new ProductModel(x));
          this.cdr.detectChanges();
        },
      });
  }

  pageChanged(event: PageEvent): void {
    this.pagination.pageIndex = event.pageIndex;
    this.pagination.pageSize = event.pageSize;
    this.loadData();
  }

  toggleExpand(element: ProductModel, event: Event) {
    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.expandedElement = element;
      if (element.type === 'ROUTE') {
        this.selectedRouteTermins(element.productId);
      }
    }
    event.stopPropagation();
  }

  isSelected(product: ProductModel): boolean {
    return this.selectedProducts.includes(product);
  }

  toggleSelection(product: ProductModel): void {
    const index = this.selectedProducts.indexOf(product);
    if (index === -1) {
      if (this.selectedType && this.selectedType !== product.type) {
        this.selectedProducts = [];
      }
      this.selectedProducts.push(product);
      this.selectedType = product.type;
    } else {
      this.selectedProducts.splice(index, 1);
      if (this.selectedProducts.length === 0) {
        this.selectedType = null;
      }
    }
  }

  isCheckboxDisabled(product: ProductModel): boolean {
    return this.selectedType && this.selectedType !== product.type || this.disabledProducts.includes(product);
  }

  withdrawSelected() {
    this.modalService.openModalWindow(StatusChangeComponent, 'withdrawProducts', { selectedProducts: this.selectedProducts, status: STATUS.ON_RETURN,  buttonLabel: 'confirm',
      title: 'enterWithdrawReason', }, { width: '1024px' }).pipe(takeUntil(this._unsubscribeAll)).subscribe(
      () => this.loadData());
    this.selectedProducts.forEach(product => {
      this.disabledProducts.push(product);
    });

    this.selectedProducts = [];
    this.selectedType = null;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  protected readonly ROLES = ROLES;
  protected readonly localStorage = localStorage;
}
