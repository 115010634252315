export class BlockedCompanyModel {
    id: number;
    companyName: string;
    blockDate: Date;
    typeOfCompany: string;
    reason: string;
    companyId:number

    constructor(obj?: any) {
        if (obj) {
            this.bind(obj);
        }
    }

    bind(obj: any) {
        this.id = obj?.id;
        this.companyName = obj?.companyName;
        this.blockDate = obj?.blockDate ? new Date(obj.blockDate) : null;
        this.typeOfCompany = obj?.typeOfCompany;
        this.reason = obj?.reason;
        this.companyId = obj?.companyId

        return this;
    }
}