import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

@Directive({
  standalone: true,
  selector: '[authView]',
  providers:[TranslocoPipe]
})
export class AuthViewDirective implements OnInit, OnDestroy {
  @Input('authView') roles: string[];
  @Input() hide: boolean = false;
  @Input() tooltipMessage: string = 'authorizationRequired';
  userRoles: string[] = [];
  private _tooltipElement: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _translatePipe:TranslocoPipe
  ) {
  }

  ngOnInit() {
    this.userRoles = localStorage.getItem('userRoles') ? JSON.parse(localStorage.getItem('userRoles')) : [];
    this.updateView();
    // Create and initialize tooltip element
    this._tooltipElement = document.createElement('div');
    this._tooltipElement.innerHTML = `
      <div class="tooltip hidden absolute text-xs p-[3px] bg-red-100 border rounded-lg border-red-400 shadow-md z-10">
        ${(this._translatePipe.transform(this.tooltipMessage))}
      </div>
    `;
    document.body.appendChild(this._tooltipElement);

    // Show tooltip on mouse move
    this.renderer.listen(this.el.nativeElement, 'mousemove', (event: MouseEvent) => {
      if (!this.hasPermission()) {
        this.showTooltip(event);
      }
    });

    // Hide tooltip on mouse leave
    this.renderer.listen(this.el.nativeElement, 'mouseleave', () => {
      this.hideTooltip();
    });
  }

  ngOnDestroy() {
    this.hideTooltip();
    this._tooltipElement.remove();
  }

  private updateView() {
    if (!this.hasPermission()) {
      this.renderer.addClass(this.el.nativeElement, 'cursor-not-allowed');
      this.el.nativeElement.addEventListener('click', (e) => {
        e.stopImmediatePropagation();
        e.stopPropagation();
      }, true);
      this.el.nativeElement.addEventListener('keydown', (e) => {
        e.preventDefault();
      }, true);
      if (this.hide) {
        this.renderer.addClass(this.el.nativeElement, 'hidden');
      } else {
        this.renderer.addClass(this.el.nativeElement, 'opacity-50');
      }
    }
  }

  private hasPermission(): boolean {
    return this.roles.some(role => this.userRoles.includes(role));
  }

  private showTooltip(event: MouseEvent) {
    const tooltip = this._tooltipElement.querySelector('.tooltip') as HTMLElement;
    tooltip.classList.remove('hidden');
    tooltip.style.top = `${event.pageY - 10}px`;
    tooltip.style.left = `${event.pageX + 10}px`;
  }


  private hideTooltip() {
    const tooltip = this._tooltipElement.querySelector('.tooltip') as HTMLElement;
    tooltip.classList.add('hidden');
  }
}
