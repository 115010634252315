import {UserModel} from './user.model';
import {AddressModel} from './address.model';
import {CompanyFileModel} from "./company-file.model";


export class CompanyModel {
    id: number;
    name: string;
    owner: string;
    type: string;
    blocked: boolean;
    phoneNumber: string;
    taxNumber: string;
    ustId: string;
    address: AddressModel | null;
    users: UserModel[];
    companyFiles: CompanyFileModel[] | null;

    constructor(obj?: any) {
        if (obj) {
            this.bind(obj);
        }
    }

    bind(obj: any) {
        this.id = obj?.id;
        this.name = obj?.name;
        this.owner = obj?.owner;
        this.type = obj?.type;
        this.blocked = obj?.blocked ?? false;
        this.phoneNumber = obj?.phoneNumber;
        this.taxNumber = obj?.taxNumber;
        this.ustId = obj?.ustId;
        this.address = new AddressModel(obj?.address) || null;
        if (Array.isArray(obj?.users)) {
            this.users = obj?.users.map((user: UserModel) => new UserModel(user));
        } else {
            this.users = [];
        }
        if (Array.isArray(obj?.companyFiles)) {
            this.companyFiles = obj?.companyFiles.map((companyFile: CompanyFileModel) => new CompanyFileModel(companyFile));
        } else {
            this.companyFiles = [];
        }
        return this;
    }
}