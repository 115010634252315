export enum STATUS {
  REJECTED = 'REJECTED',
  QC = 'QC',
  ON_SALE = 'ON_SALE',
  SOLD = 'SOLD',
  ON_RETURN = 'ON_RETURN',
  CUSTOMER_PAID = 'CUSTOMER_PAID',
  SELLER_PAID = 'SELLER_PAID',
  WAITING = 'WAITING',
  RESCHEDULE = 'RESCHEDULE',
  FINISH = 'FINISH'
}
