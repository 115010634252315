<ng-container [ngSwitch]="status">
    <span *ngSwitchCase="STATUS.SOLD" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchCase="STATUS.QC" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-orange-200 text-orange-800 dark:bg-orange-600 dark:text-orange-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchCase="STATUS.REJECTED" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchCase="STATUS.ON_RETURN" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchCase="STATUS.ON_SALE" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchCase="STATUS.CUSTOMER_PAID" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-green-400 text-green-800 dark:bg-green-600 dark:text-green-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchCase="STATUS.SELLER_PAID" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-lime-400 text-lime-800 dark:bg-lime-600 dark:text-lime-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchCase="STATUS.WAITING" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-yellow-200 text-yellow-700 dark:bg-yellow-600 dark:text-yellow-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchCase="STATUS.RESCHEDULE" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-purple-200 text-purple-700 dark:bg-purple-600 dark:text-purple-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchCase="STATUS.FINISH" [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  bg-red-200 text-red-700 dark:bg-red-600 dark:text-red-50 leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
    <span *ngSwitchDefault [ngClass]="{'text-gray-400': grayedOut}"
          class="inline-flex items-center justify-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase min-w-36  leading-relaxed whitespace-nowrap">{{ status | transloco }}</span>
</ng-container>
