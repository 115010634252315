<div class="w-full px-4">

  <!-- Add team member -->
  <div class="w-full">
      <div class="flex flex-row justify-between items-center mb-4"><strong>{{ 'findTeamMembers'|transloco }}</strong>
          <button mat-flat-button class="bg-primary text-white"  (click)="addUser()">{{ 'addUser' | transloco }}</button>
      </div>
    <mat-form-field
      class="w-full"
      [subscriptSizing]="'dynamic'">

      <mat-icon
        class="icon-size-5"
        [svgIcon]="'heroicons_solid:user'"
        matPrefix></mat-icon>
      <input
        [formControl]="search"
        matInput
        [placeholder]="'findUser'|transloco">
      <button
        mat-icon-button
        matSuffix>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <!-- Team members -->
  <div class="flex flex-col mt-8 divide-y border-t border-b">
    <ng-container *ngFor="let member of users$ | async; trackBy: trackByFn;">
      <div class="flex flex-col sm:flex-row sm:items-center py-6"
            [ngClass]="member.deleted?'opacity-50':''">
        <div class="flex items-center">
          <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
            <ng-container *ngIf="member.avatar">
              <img
                class="object-cover w-full h-full"
                [src]="member.avatar"
                alt="Contact avatar" />
            </ng-container>
            <ng-container *ngIf="!member.avatar">
              <div
                class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                {{ member.name.charAt(0) }}
              </div>
            </ng-container>
          </div>
          <div class="ml-4">
            <div class="font-medium">{{ member.name }}</div>
            <div class="text-secondary">{{ member.email }}</div>
            <div class="text-secondary">{{ member.company.name }}</div>
          </div>
        </div>
        <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
          <div class="order-2 sm:order-1 ml-4 sm:ml-0">
            <mat-form-field
              class="fuse-mat-dense w-60"
              [subscriptSizing]="'dynamic'">
              <mat-select
                (selectionChange)="updateRole(member,$event)"
                [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'"
                [value]="member.roles.length>0 ? member.roles[0]['name'] : null"
                disableOptionCentering
                #roleSelect="matSelect">
                <mat-select-trigger class="text-md">
                  <span class="ml-1 font-medium">{{ roleSelect.value | transloco }}</span>
                </mat-select-trigger>
                <ng-container *ngFor="let role of roles">
                  <mat-option
                    class="h-auto py-4 leading-none"
                    [value]="role.value">
                    <div class="font-medium">{{ role.label | transloco }}</div>
                    <div class="mt-1.5 text-sm whitespace-normal leading-normal text-secondary">{{ role.description }}
                    </div>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="order-1 sm:order-2 sm:ml-3">
            <button mat-icon-button (click)="deleteUser(member)">
              <mat-icon
                class="text-hint"
                [svgIcon]="'heroicons_outline:trash'"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="flex items-center justify-center" *ngIf="pagination?.length>9">
    <mat-paginator
      class="w-full"
      [length]="pagination?.length"
      [pageSize]="pagination?.pageSize || 10"
      [pageIndex]="pagination?.pageIndex || 0"
      [pageSizeOptions]="[5, 10, 20, 50]"
      [showFirstLastButtons]="true"
      (page)="pageChanged($event)">
    </mat-paginator>
  </div>

</div>
