import { TerminModel } from './termin.model';

export class VoiceModel {
    id: number;
    type: string;
    name: string;
    path: string;
    termin: TerminModel | null;
    access: boolean;

    constructor(obj?: any) {
        if (obj) {
            this.bind(obj);
        }
    }

    bind(obj: any) {
        this.id = obj?.id;
        this.type = obj?.type;
        this.name = obj?.name;
        this.path = obj?.path + "?token=" + localStorage.getItem("accessToken");
        this.termin = obj?.termin ? new TerminModel(obj.termin) : null;
        this.access = obj?.access ?? false;
        return this;
    }
}
