import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { CompanyComponent } from '../../../../shared/components/company/company.component';
import { TranslocoPipe } from '@ngneat/transloco';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { UserService } from '../../../../core/user/user.service';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { ROLES } from '../../../../shared/model/user.roles.enum';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../../../../shared/components/base-modal/base-modal.component';
import { CompanyModel } from '../../../../shared/model/company.model';
import { GenericResponseModel } from '../../../../shared/model/generic.model';
import { PaginateResponse } from '../../../../shared/model/paginate.type';
import { CompanyService } from '../../../../shared/services/company.service';
import { SnackbarService } from '../../../../shared/services/snackbar/snackbar.service';

@Component({
  selector: 'app-add-user',
  standalone: true,
  imports: [MatExpansionModule, AddressComponent, CompanyComponent, TranslocoPipe, FormsModule, MatButton, MatError, MatFormField, MatIcon, MatIconButton, MatInput, MatLabel, MatOption, MatPrefix, MatSelect, MatSuffix, NgIf, ReactiveFormsModule, MatDivider, NgForOf, MatAutocomplete, MatAutocompleteTrigger, AsyncPipe],
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUserComponent implements OnInit, OnDestroy {
  readonly panelOpenState = signal(false);
  public _unsubscribeAll: Subject<any> = new Subject<any>();
  userForm: FormGroup;
  roles: string[];
  companies$: BehaviorSubject<CompanyModel[]> = new BehaviorSubject<CompanyModel[]>([]);
  filterCompanyControl: UntypedFormControl = new UntypedFormControl(null);

  constructor(private _snackBarService: SnackbarService, private fb: FormBuilder, private userService: UserService, private dialogRef: MatDialogRef<BaseModalComponent>, private companyService: CompanyService) {
    this.roles = Object.values(ROLES);
    this.createForm();
  }

  ngOnInit() {
    this.loadCompany();
    this.filterCompanyControl.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this._unsubscribeAll)).subscribe(value => {
      this.loadCompany(value);
    });
  }

  createForm() {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      name: ['', Validators.required],
      appeal: ['', Validators.required],
      roles: this.fb.control([], Validators.required),
      avatar: ['',],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      password: ['', Validators.required],
      address: this.fb.group({
        postCode: ['', Validators.required],
        location: ['', Validators.required],
        city: ['', Validators.required],
        street: ['', Validators.required],
      }),
      company: this.fb.group({
        id: [''],
        name: ['', Validators.required],
        type: ['', Validators.required],
        owner: ['', Validators.required],
        taxNumber: ['', Validators.required],
        ustId: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        address: this.fb.group({
          postCode: ['', Validators.required],
          location: ['', Validators.required],
          city: ['', Validators.required],
          street: ['', Validators.required],
        }),
      }),
    });
  }

  onSubmit() {
    if (this.userForm.valid) {
      this.userService.post<any>(this.userForm.value, '').pipe(takeUntil(this._unsubscribeAll)).subscribe({
        next: (response) => {
          this.dialogRef.close();
        },
        error: (error: any) => {
          if (error && error.error && !error.error.success) {
            this._snackBarService.openSnackBar(
              { title: 'Error', message: error.error.message },
              'warn',
            );
          }
        },
      });
    }
  }

  loadCompany(query?: string) {
    this.companyService
      .get<GenericResponseModel<PaginateResponse<CompanyModel>>>(`filter`, { filter: query ? query : '' })
      .subscribe({
        next: (response) => {
          this.companies$.next(response.data['content'].map(x => new CompanyModel(x)));
        },
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  setRole($event: MatSelectChange) {
    this.userForm.controls['roles'].patchValue([$event.value]);
  }

  selectCompany(event: MatSelectChange) {
    const company = this.companies$.value.find(value => value.id === event.value);
    this.userForm.get('company').patchValue(company);
  }
}