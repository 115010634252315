<form [formGroup]="blockForm" (ngSubmit)="onSubmit()" class="flex flex-col gap-8 overflow-hidden">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
        <mat-select [formControlName]="'company'" [placeholder]=" 'chooseCompany' | transloco">
            <div class="p-2 mb-2">
                <input
                        [placeholder]="'searchCompanyName' | transloco"
                        [formControlName]="'filter'"
                        class="bg-gray-200  border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500">
            </div>
            <mat-option *ngFor="let company of companies$ | async" [value]="company.id">
                {{ company.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="flex flex-col w-auto lg:flex-row gap-8">
        <mat-checkbox [formControlName]="'blockPermanently'"
                      [ngClass]="{
                        'bg-gray-300 text-gray-500': blockForm.get('deleteMembership').value,
                        'bg-orange-300 text-black': !blockForm.get('deleteMembership').value
                      }"
                      [disabled]="blockForm.get('deleteMembership').value"
                      class="custom-checkbox bg-orange-300 lg:w-1/2 rounded-2xl">{{ 'blockPermanently' | transloco }}
        </mat-checkbox>
        <mat-checkbox [formControlName]="'deleteMembership'"
                      [ngClass]="{
                        'bg-gray-300 text-gray-500': blockForm.get('blockPermanently').value,
                        'bg-orange-400 text-black': !blockForm.get('blockPermanently').value
                      }"
                      [disabled]="blockForm.get('blockPermanently').value"
                      class="custom-checkbox bg-orange-400 lg:w-1/2  rounded-2xl">{{ 'deleteMembership' | transloco }}
        </mat-checkbox>
    </div>
    <mat-form-field class="w-full" subscriptSizing="dynamic">
            <textarea matInput class="p-4 overflow-y-auto resize rounded-2xl"
                      [placeholder]="'pleaseEnterTheReason' | transloco" rows="15" [formControlName]="'reason'"></textarea>
    </mat-form-field>
    <div class="flex justify-end">
        <button mat-flat-button class="bg-red-800 text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                type="submit"
                [disabled]="blockForm.invalid || (!blockForm.get('blockPermanently').value && !blockForm.get('deleteMembership').value)"
        >{{ 'confirm' | transloco }}
        </button>
    </div>
</form>

