<div class="flex flex-col flex-auto min-w-0 space-y-8">
    <div class="overflow-x-auto shadow-md bg-white">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="w-full shadow-lg">
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef class="py-2 px-4 border-b whitespace-nowrap">
                    {{ 'title' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element" class="py-2 px-4 border-b whitespace-nowrap">
                    {{ element.title }}
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="py-2 px-4 border-b whitespace-nowrap">
                    {{ 'description' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element" class="py-2 px-4 border-b whitespace-nowrap">
                    <textarea rows="4" readonly class="w-full m-2 p-1 pr-2 border border-slate-300 rounded-lg bg-slate-50 mr-4"
                    >{{ element.description }}</textarea>
                </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="py-2 px-4 border-b whitespace-nowrap">
                    {{ 'time' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element" class="py-2 px-4 border-b whitespace-nowrap">
                    {{ element.created | date:'dd.MM.yyyy HH:mm' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="py-2 px-4 border-b whitespace-nowrap">
                    {{ 'actions' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element" class="py-2 px-4 border-b whitespace-nowrap">
                    <button mat-icon-button color="warn" (click)="deleteNotification(element)">
                        <mat-icon
                                class="text-hint"
                                [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-primary-50"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="hover:bg-gray-100 active:bg-gray-200"></tr>
        </table>
        <mat-paginator
                [length]="pagination?.length"
                [pageSize]="pagination?.pageSize || 20"
                [pageIndex]="pagination?.pageIndex || 0"
                [pageSizeOptions]="[5, 10, 20, 100]"
                [showFirstLastButtons]="true"
                (page)="pageChanged($event)">
        </mat-paginator>
    </div>
    <div class="sticky left-0 bottom-0 bg-gray-100 z-50 pb-4">
        <div class="bg-primary-50 border-y border-primary-200 w-full p-4 flex items-center justify-between">
            <ng-container>
                <strong>{{ 'total' | transloco }} {{ pagination?.length }} {{ 'notifications' | transloco }}</strong>
            </ng-container>
        </div>
    </div>
</div>