<div class="flex flex-col flex-auto min-w-0 space-y-8">
    <div class="overflow-x-auto shadow-md bg-white">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="w-full shadow-lg">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'name' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap"
                    [ngClass]="{'text-gray-400': isCheckboxDisabled(element) || (selectedType && element.type !== selectedType)}">{{ element.name }} {{ element.nameSuffix | transloco }}
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap"> {{ 'type' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap capitalize"
                    [ngClass]="{'text-gray-400': isCheckboxDisabled(element) || (selectedType && element.type !== selectedType)}">{{ element.type | transloco }}
                </td>
            </ng-container>
            <ng-container matColumnDef="appointmentDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'appointmentDate' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap"
                    [ngClass]="{'text-gray-400': isCheckboxDisabled(element) || (selectedType && element.type !== selectedType)}">{{ element.appointmentDate | date:'dd.MM.yyyy HH:mm' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'status' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element" class="py-2 px-4 border-b whitespace-nowrap"
                    [ngClass]="{'text-gray-400': isCheckboxDisabled(element) || (selectedType && element.type !== selectedType)}">
                    <app-product-status-selector [status]="element?.status"
                                                 [grayedOut]="selectedType && element.type !== selectedType"></app-product-status-selector>
                </td>
            </ng-container>
          <ng-container *ngIf="displayedColumns.includes('price')" matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                class="py-2 px-4 border-b whitespace-nowrap"> {{ 'price' | transloco }}
            </th>
            <td mat-cell *matCellDef="let element"
                class="py-2 px-4 border-b whitespace-nowrap capitalize"
                [ngClass]="{'text-gray-400': isCheckboxDisabled(element) || (selectedType && element.type !== selectedType)}">{{  element.price | currency : 'EUR' : 'symbol' :'1.2-2'  }}
            </td>
          </ng-container>
            <ng-container *ngIf="displayedColumns.includes('seller')" matColumnDef="seller">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    class="py-2 px-4 border-b whitespace-nowrap"> {{ 'seller' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="py-2 px-4 border-b whitespace-nowrap capitalize"
                    [ngClass]="{'text-gray-400': isCheckboxDisabled(element) || (selectedType && element.type !== selectedType)}">{{ element.seller }}
                </td>
            </ng-container>
            <ng-container *ngIf="!isSeller" matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef
                    class="py-2 px-4 border-b whitespace-nowrap">{{ 'withdraw' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element" class="py-2 px-4 border-b whitespace-nowrap">
                    <mat-checkbox class="custom-checkbox" [checked]="isSelected(element)"
                                  (change)="toggleSelection(element)"
                                  [disabled]="isCheckboxDisabled(element)"></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions"
                    class="py-2 px-4 border-b whitespace-nowrap">&nbsp;
                </th>
                <td mat-cell *matCellDef="let element" class="py-2 px-4 border-b whitespace-nowrap">
                    <button mat-icon-button aria-label="expand row" *ngIf="element.type === 'ROUTE'"
                            (click)="toggleExpand(element, $event)">
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"
                    class="py-2 px-3 bg-primary-50">
                    <div class="flex overflow-hidden transition-all duration-300"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div *ngIf="expandedElement?.id === element.id && element.type === 'ROUTE'" class="w-full">
                            <table class="w-full overflow-x-auto">
                                <tbody>
                                <tr *ngFor="let termin of expandedRoute"
                                    class="flex flex-row border-b last:border-0 py-2">
                                    <td width="25.5%"
                                        class="py-2 whitespace-nowrap">{{ termin.name }} {{ termin.nameSuffix }}
                                    </td>
                                    <td width="11.2%"
                                        class="py-2 whitespace-nowrap capitalize">{{ termin.type | transloco }}
                                    </td>
                                    <td width="23.2%"
                                        class="py-2 whitespace-nowrap">{{ termin.appointmentDate | date:'dd.MM.yyyy HH:mm' }}
                                    </td>
                                    <td width="40.1%" class="py-2 whitespace-nowrap">
                                        <app-product-status-selector
                                                [status]="termin?.status"></app-product-status-selector>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-primary-50"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="hover:bg-gray-100 active:bg-gray-200" (click)="onRowClick(element, $event)">>
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="h-0 bg-gray-50"></tr>
        </table>
        <mat-paginator
                [length]="pagination?.length"
                [pageSize]="pagination?.pageSize || 20"
                [pageIndex]="pagination?.pageIndex || 0"
                [pageSizeOptions]="[5, 10, 20, 100]"
                [showFirstLastButtons]="true"
                (page)="pageChanged($event)">
        </mat-paginator>
    </div>
    <div class="sticky left-0 bottom-0 bg-gray-100 z-50 pb-4">
        <div class="bg-primary-50 border-y border-primary-200 w-full p-4 flex items-center justify-between">
            <strong> {{ 'total' | transloco }} {{ pagination?.length }} {{ 'products' | transloco }}</strong>
            <button mat-flat-button *ngIf="!isSeller && mode !== 'pendingProduct'"
                    class="text-white"
                    [ngClass]="{
              'bg-red-800': selectedProducts.length > 0,
              'bg-gray-300': selectedProducts.length === 0,
              'cursor-not-allowed': selectedProducts.length === 0
            }"
                    [disabled]="selectedProducts.length === 0"
                    (click)="withdrawSelected()">{{ mode === ('return' | transloco) ? ('returnProduct' | transloco ): ('withdrawProduct' | transloco )}}
            </button>
        </div>
    </div>
</div>