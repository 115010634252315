import {DatePipe, NgIf} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDrawerToggleResult} from '@angular/material/sidenav';
import {RouterLink} from '@angular/router';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {CompanyFileModel} from '../../../../../../shared/model/company-file.model';
import {CompanyEditComponent} from '../../company-edit.component';
import {environment} from '../../../../../../../environments/environment';
import {TranslocoPipe} from "@ngneat/transloco";
import {CompanyFileService} from "../../../../../../shared/services/company-file.service";
import {FormsModule} from "@angular/forms";
import {Confirmable} from "../../../../../../shared/decorators/confirmable.decorator";
import {FuseConfirmationService} from "../../../../../../../@fuse/services/confirmation";

@Component({
    selector: 'company-file-edit',
    templateUrl: './company-file-edit.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, RouterLink, MatIconModule, NgIf, TranslocoPipe, DatePipe, FormsModule],
})
export class CompanyFileEditComponent implements OnInit, OnDestroy {
    @Input() selectedFile$: BehaviorSubject<CompanyFileModel> = new BehaviorSubject<CompanyFileModel>(null);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private companyEditComponent: CompanyEditComponent,
        private companyFileService: CompanyFileService,
        private confirmationService: FuseConfirmationService
    ) {
    }

    ngOnInit(): void {
        this.selectedFile$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            if (value && value.description) {
                const isJson = value.description.trim().startsWith('{') && value.description.trim().endsWith('}');
                if (isJson) {
                    try {
                        const parsedDescription = JSON.parse(value.description);
                        if (typeof parsedDescription === 'object' && parsedDescription.description) {
                            value.description = parsedDescription.description;
                        }
                    } catch (error) {
                        console.warn('descriptionIsNotInJsonFormat', error);
                    }
                }
            }
            this._changeDetectorRef.detectChanges();
        });
    }

    downloadFile() {
        const link = document.createElement('a');
        link.href = `${environment.apiUrl}/${this.selectedFile$.value?.path}`;
        link.download = this.selectedFile$.value?.path;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    updateDescription(){
        this.companyFileService.patch(null, {description: this.selectedFile$.value.description}, `${this.selectedFile$.value.id}`)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.selectedFile$.next(null);
                    this.companyEditComponent.matDrawer.close();
                },
                error: (err) => {
                    console.error('errorUpdatingDescription', err);
                },
            });

    }

    @Confirmable({
        title: 'confirmation',
        message: 'areYouSureYouWantToDeleteTheFile',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation-circle',
            color: 'warn',
        },
    })
    deleteFile() {
      this.companyFileService.delete<any>(null, `${this.selectedFile$.value.id}`)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe({
              next: (response) => {
                  this.companyEditComponent.selectedCompany.companyFiles =
                  response.data.filter(file => file.id !== this.selectedFile$.value.id);
                  this._changeDetectorRef.detectChanges();
                  this.selectedFile$.next(null);
                  this.companyEditComponent.matDrawer.close();
              },
              error: (err) => {
                  console.error('errorDeletingFile', err);
              },
          });
    }

    closeDrawer(): Promise<MatDrawerToggleResult> {
        return this.companyEditComponent.matDrawer.close();
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    protected readonly environment = environment;

}
