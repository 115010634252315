<form @fadeInLeft [formGroup]="companyForm">
  <!-- Company -->
  <div class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none capitalize">{{ 'company' | transloco }}</div>
  <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
    <!-- CompanyName -->
    <div class="sm:col-span-2">
      <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
        <mat-label>{{ 'companyName' | transloco }}</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:building-office'"
          matPrefix
        ></mat-icon>
        <input [formControlName]="'name'" matInput />
      </mat-form-field>
    </div>
    <div class="sm:col-span-2">
      <mat-form-field class="w-full" subscriptSizing="dynamic">
        <mat-label>{{ 'type' | transloco }}</mat-label>
        <mat-select formControlName="type">
          <mat-option value="seller">{{ 'seller' | transloco }}</mat-option>
          <mat-option value="customer">{{ 'customer' | transloco }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
    <!-- companyOwner -->
    <div class="sm:col-span-2">
      <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
        <mat-label>{{ 'companyOwner' | transloco }}</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'mat_solid:real_estate_agent'"
          matPrefix
        ></mat-icon>
        <input [formControlName]="'owner'" matInput />
      </mat-form-field>
    </div>

    <!-- taxNumber -->
    <div class="sm:col-span-2">
      <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
        <mat-label>{{ 'taxNumber' | transloco }}</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:briefcase'"
          matPrefix
        ></mat-icon>
        <input [formControlName]="'taxNumber'" matInput />
      </mat-form-field>
    </div>
    <!-- ustId -->
    <div class="sm:col-span-2">
      <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
        <mat-label>{{ 'ustId' | transloco }}</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:identification'"
          matPrefix
        ></mat-icon>
        <input [formControlName]="'ustId'" matInput />
      </mat-form-field>
    </div>
    <!-- Phone -->
    <div class="sm:col-span-2">
      <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
        <mat-label>{{ 'phoneNumber' | transloco }}</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:phone'"
          matPrefix
        ></mat-icon>
        <input [formControlName]="'phoneNumber'" matInput />
      </mat-form-field>
    </div>
  </div>
</form>
<form [formGroup]="addressForm">
  <mat-accordion class="mx-6">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'companyAddress' | transloco }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="pb-10">
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
          <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>{{ 'postCode' | transloco }}</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:envelope'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'postCode'" matInput />
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>{{ 'location' | transloco }}</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:paper-airplane'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'location'" matInput />
            </mat-form-field>
          </div>
        </div>

        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
          <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>{{ 'city' | transloco }}</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:home'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'city'" matInput />
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>{{ 'street' | transloco }}</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:map'"
                matPrefix
              ></mat-icon>
              <input [formControlName]="'street'" matInput />
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</form>
<div class="flex items-center justify-end mt-11">
  <button mat-stroked-button type="button" (click)="onReset()">
    {{ 'cancel' | transloco }}
  </button>
  <button
    (click)="onSubmit()"
    class="ml-4"
    mat-flat-button
    [disabled]="!companyForm.valid || !addressForm.valid"
    type="button"
    [color]="'primary'"
  >
    {{ company?.id ? ('updateCompany' | transloco) : ('addCompany' | transloco) }}
  </button>
</div>
