<div class="h-full bg-primary-700 rounded-lg">
  <div class="flex flex-row items-center justify-between py-2">
    <p style="margin-bottom: 0 !important" class="px-3 py-0 text-lg font-semibold text-white">
      {{ data.title | transloco }}
    </p>
    <div class="flex flex-row px-2">
      <a (click)="minimize()"
         class="m-0 flex items-center justify-center rounded-l-md border border-gray-400 p-0 text-lg hover:bg-slate-600">
        <mat-icon class="mx-1 text-gray-300" [inline]="true"> remove </mat-icon>
      </a>
      <a (click)="fullscreen()"
         class="m-0 flex items-center justify-center border border-gray-400 p-0 text-lg hover:bg-slate-600">
        <mat-icon class="mx-1 text-gray-300" [inline]="true"> fullscreen </mat-icon>
      </a>
      <a (click)="dialog.closeAll()"
         class="m-0 flex items-center justify-center rounded-r-md border border-gray-400 p-0 text-lg hover:bg-slate-600">
        <mat-icon class="mx-1 text-gray-300" [inline]="true"> close </mat-icon>
      </a>
    </div>
  </div>
  <div class="content h-full bg-primary-700 p-2">
    <div class="rounded-lg content-area bg-gray-100 dark:bg-gray-800 p-3">
      <ng-template [cdkPortalOutlet]="portal" (attached)="loadSubCompInputs($event)"></ng-template>
    </div>
  </div>
</div>
